class ClipboardService {
    async writeToClipboard(text: string) {
        try {
            setTimeout(async () => {
                await navigator.clipboard.writeText(text);
            }, 0);
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    }

    async writeHtmlToClipboard(html: string) {
        try {
            const blob = new Blob([html], {
                type: "text/html"
            });
            const clipboardItem = new ClipboardItem({
                "text/html": blob
            });
            setTimeout(async () => {
                await navigator.clipboard.write([clipboardItem]);
            }, 0);
        } catch (error) {
            console.error('Failed to copy HTML:', error);
        }
    }
}

export default new ClipboardService();

/*
Usage Example:

import clipboardService from './helpers/ClipboardService';

// Copy plain text
clipboardService.writeToClipboard('Hello World');

// Copy HTML content
clipboardService.writeHtmlToClipboard('<h1>Hello World</h1>');
*/
