export const JobTitle: React.FC<{
  jobTitle: string | null;
  company: string | null;
  textColor?: string;
}> = ({ jobTitle = 'Developer', company = 'Tapt', textColor = '#848083' }) => {
  const position = [jobTitle, company].filter(Boolean).join(' at ');

  if (!position) {
    return null;
  }

  return (
    <tr>
      <td
        style={{
          fontFamily: 'Verdana, Geneva, sans-serif',
          lineHeight: '22px',
          height: '22px',
          textAlign: 'left',
          paddingRight: '16px',
          paddingBottom: '16px',
        }}
      >
        <span style={{ color: textColor, fontSize: '15px', fontWeight: 400 }}>
          {position}
        </span>
      </td>
    </tr>
  );
};