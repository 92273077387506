import { classNames } from '@/helpers/strings';

export const BADGE_KIND = {
  BRAND: 'brand',
  GRAY: 'gray',
  GREEN: 'green',
  RED: 'red',
  ORANGE: 'orange',
  IN_PRINT: 'in_print',
  FULLFILED: 'fullfiled',
  PINK: 'pink',
  EMERALD: 'emerald',
  AMBER: 'amber',
  BLUE: 'blue',
} as const;

const BadgeKinds = {
  [BADGE_KIND.BRAND]: {
    textColor: 'text-brand-500',
    backgroundColor: 'bg-brand-100',
  },
  [BADGE_KIND.GRAY]: {
    textColor: 'text-gray-800',
    backgroundColor: 'bg-gray-100',
  },
  [BADGE_KIND.GREEN]: {
    textColor: 'text-gray-800',
    backgroundColor: 'bg-green-100',
  },
  [BADGE_KIND.AMBER]: {
    textColor: 'text-amber-800',
    backgroundColor: 'bg-amber-50',
  },
  [BADGE_KIND.RED]: {
    textColor: 'text-red-800',
    backgroundColor: 'bg-red-100',
  },
  [BADGE_KIND.ORANGE]: {
    textColor: 'text-gray-800',
    backgroundColor: 'bg-orange-100',
  },
  [BADGE_KIND.IN_PRINT]: {
    textColor: 'text-blue-800',
    backgroundColor: 'bg-in_print-100',
  },
  [BADGE_KIND.FULLFILED]: {
    textColor: 'text-gray-800',
    backgroundColor: 'bg-fullfiled-100',
  },
  [BADGE_KIND.PINK]: {
    textColor: 'text-pink-800',
    backgroundColor: 'bg-pink-100',
  },
  [BADGE_KIND.EMERALD]: {
    textColor: 'text-emerald-800',
    backgroundColor: 'bg-emerald-150',
  },
  [BADGE_KIND.BLUE]: {
    textColor: 'text-blue-800',
    backgroundColor: 'bg-blue-100',
  },
};

export type BadgeKind = (typeof BADGE_KIND)[keyof typeof BADGE_KIND];

const Badge = ({
  text,
  kind,
  className = '',
  backgroundColor = '',
  textColor = '',
}: {
  text: string;
  kind: BadgeKind;
  className?: string;
  backgroundColor?: string;
  textColor?: string;
}) => (
  <span
    className={classNames(
      className,
      text
        ? `inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium`
        : `items-center p-2.5 text-xs rounded-full max-h-2.5 font-medium`,
      BadgeKinds[kind].textColor,
      BadgeKinds[kind].backgroundColor,
      backgroundColor,
      textColor,
    )}
  >
    {text}
  </span>
);

export default Badge;
