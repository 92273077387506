export const NameTitle: React.FC<{ name: string; textColor?: string }> = ({
  name = 'Firstname Lastname',
  textColor = '#1B3B49',
}) => {
  return (
    <td
      style={{
        fontSize: '15px',
        fontFamily: 'Verdana, Geneva, sans-serif',
        fontWeight: 700,
        lineHeight: '22px',
        height: '22px',
        textAlign: 'left',
        paddingBottom: '8px',
      }}
    >
      <span style={{ color: textColor }}>{name}</span>
    </td>
  );
};