import { useState } from 'react';

import { AxiosError } from 'axios';

import accountsAPI from '@/api/accounts';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button from '@/components/Button';
import Input from '@/components/Input';
import LayoutStandard from '@/components/LayoutStandard';
import { validateEmail } from '@/helpers/validate';

export default function ResetPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [success, setSuccess] = useState(false);

  function onForgotPassword() {
    setEmailError(false);

    if (validateEmail(email)) {
      setLoading(true);
      accountsAPI
        .forgotPassword({ forgot: { email } })
        .then(() => setSuccess(true))
        .catch((e: AxiosError) => {
          if (e.response?.status === 422) {
            setEmailError(true);
          } else {
            throw e;
          }
        })
        .finally(() => setLoading(false));
    } else {
      setEmailError(true);
    }
  }

  return (
    <LayoutStandard
      heading="Reset your Tapt password"
      description="Enter your email and we'll send you instructions on how to reset your password"
    >
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 border border-gray-100 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            {success && (
              <SuccessAlert message="If your account exists please check your inbox for a password reset email" />
            )}
            {emailError && <ErrorAlert message="Please enter a valid email" />}
            <Input
              label="Email address"
              type="email"
              value={email}
              onChange={setEmail}
              error={emailError}
            />

            <div>
              <Button
                buttonText="Continue"
                onClick={onForgotPassword}
                fullWidth
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutStandard>
  );
}
