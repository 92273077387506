import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

import {
  BarElement,
  CategoryScale,
  Chart,
  Chart as ChartJS,
  CoreScaleOptions,
  Legend,
  LinearScale,
  Scale,
  Title,
  Tooltip,
} from 'chart.js';
import useBreakpoint from 'use-breakpoint';

import { IMostActiveCard } from '@/api/dashboard';
import DefaultAvatar from '@/assets/svg/default_avatar.svg';
import FirstIcon from '@/assets/svg/first_icon.svg';
import SecondIcon from '@/assets/svg/second_icon.svg';
import StarIcon from '@/assets/svg/star_icon.svg';
import ThirdIcon from '@/assets/svg/third_icon.svg';

import { ColumnChartContainer } from '../styleds/ColumnChart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface IColumnChart {
  dataMostActiveCards: IMostActiveCard[];
}

interface CanvasProfileImageSnapshot {
  id: number | undefined;
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  profile: {
    profileHash: string | undefined;
  };
}

export const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };
const LIMIT_PROFILES = 6;
const DECIMAL_NUMBERS = 2;

const profileImageWidth = 32;
const profileImageHeight = 26;
const profileImageLeft = 18;
const profileImageBottom = 20;

const numberIconWidth = 8;
const numberIconHeight = 14;
const numberIconTop = 5;
const numberIconLeft = 5;

const starIconWidth = 17.5;
const starIconHeight = 17;
const starIconTop = 16;
const starIconLeft = 8;

const hasSamePos = (
  current: CanvasProfileImageSnapshot,
  other: CanvasProfileImageSnapshot,
) => {
  return (
    current.startX.toFixed(DECIMAL_NUMBERS) ===
      other.startX.toFixed(DECIMAL_NUMBERS) &&
    current.startY.toFixed(DECIMAL_NUMBERS) ===
      other.startY.toFixed(DECIMAL_NUMBERS) &&
    current.endX.toFixed(DECIMAL_NUMBERS) ===
      other.endX.toFixed(DECIMAL_NUMBERS) &&
    current.endY.toFixed(DECIMAL_NUMBERS) ===
      other.endY.toFixed(DECIMAL_NUMBERS)
  );
};

function drawProfileImage(
  ctx: CanvasRenderingContext2D,
  index: number,
  dataMostActiveCards: IMostActiveCard[],
  xAxis: Scale<CoreScaleOptions>,
  yAxis: Scale<CoreScaleOptions>,
) {
  const image = new Image();
  image.src =
    dataMostActiveCards[index]?.profile?.photo?.thumb_url || DefaultAvatar;

  const startX = xAxis.getPixelForTick(index) - profileImageLeft;
  const startY = yAxis.bottom + profileImageBottom;
  const endX = startX + profileImageWidth;
  const endY = startY + profileImageHeight;
  const arcRadius = profileImageHeight / 2;

  ctx.save();
  ctx.beginPath();
  ctx.arc(
    startX + profileImageWidth / 2,
    startY + profileImageHeight / 2,
    arcRadius,
    0,
    Math.PI * 2,
    true,
  );
  ctx.closePath();
  ctx.clip();

  ctx.drawImage(image, startX, startY, profileImageWidth, profileImageHeight);

  ctx.beginPath();
  ctx.arc(0, 0, arcRadius, 0, Math.PI * 2, true);
  ctx.clip();
  ctx.closePath();
  ctx.restore();
  return { startX, startY, endX, endY };
}

function drawBarIcon(
  ctx: CanvasRenderingContext2D,
  iconSrc: string,
  startX: number,
  startY: number,
  iconWidth: number,
  iconHeight: number,
) {
  const first = new Image();
  first.src = iconSrc;
  ctx.drawImage(first, startX, startY, iconWidth, iconHeight);
}

const getCanvasProfiles = (
  x: number,
  y: number,
  canvasProfiles: CanvasProfileImageSnapshot[],
) => {
  const incrementConstant = 20;
  return canvasProfiles.find((item, index) => {
    let approxX = x + index * incrementConstant;
    if (index < 2) {
      approxX += profileImageWidth / (LIMIT_PROFILES - index);
    }
    if (index > 3) {
      approxX -= profileImageWidth / index;
    }
    const approxY = y;
    return (
      item.startX <= approxX &&
      item.endX >= approxX &&
      item.startY <= approxY &&
      item.endY >= approxY
    );
  });
};

export default function ColumnChart(props: IColumnChart) {
  const { dataMostActiveCards } = props;
  const labels = ['', '', '', '', '', ''];
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
  const [canvasProfileImagesSnapshot, setCanvasProfileImagesSnapshot] =
    useState<CanvasProfileImageSnapshot[]>([]);

  const navigate = useNavigate();

  const imagesSnapShot: CanvasProfileImageSnapshot[] = [];
  let hasSnapShot = false;

  const plugin = [
    {
      afterDraw: (chart: Chart) => {
        const { ctx } = chart;
        const { xAxis, yAxis } = chart.scales;

        xAxis.ticks.forEach((_: any, index: number) => {
          const { startX, startY, endX, endY } = drawProfileImage(
            ctx,
            index,
            dataMostActiveCards,
            xAxis,
            yAxis,
          );

          const imageSnapShot: CanvasProfileImageSnapshot = {
            id: dataMostActiveCards[index]?.profile?.id,
            startX,
            startY,
            endX,
            endY,
            profile: {
              profileHash: dataMostActiveCards[index]?.profile?.profile_hash,
            },
          };

          const existedImageSnapShot = canvasProfileImagesSnapshot.find(
            item => {
              return hasSamePos(item, imageSnapShot);
            },
          );
          if (!existedImageSnapShot) {
            imagesSnapShot.push(imageSnapShot);
          }
        });
        if (!hasSnapShot && imagesSnapShot.length <= LIMIT_PROFILES) {
          setCanvasProfileImagesSnapshot(imagesSnapShot);
          hasSnapShot = true;
        }

        const dataset = chart.getDatasetMeta(0).data;
        const yAxisBottom = yAxis.bottom;
        yAxis.ticks.forEach((_: any, index: number) => {
          if (index === 0 && dataset.length > 0) {
            const current = dataset[index];
            const top = current.y + numberIconTop;
            drawBarIcon(
              ctx,
              FirstIcon,
              xAxis.getPixelForTick(index) - numberIconLeft,
              top,
              numberIconWidth,
              numberIconHeight,
            );
            drawBarIcon(
              ctx,
              StarIcon,
              xAxis.getPixelForTick(index) - starIconLeft,
              top + starIconTop,
              starIconWidth,
              starIconHeight,
            );
          } else if (index === 1 && dataset.length > 1) {
            const current = dataset[index];
            const top = current.y + numberIconTop;
            if (numberIconHeight < yAxisBottom - current.y) {
              drawBarIcon(
                ctx,
                SecondIcon,
                xAxis.getPixelForTick(index) - numberIconLeft,
                top,
                numberIconWidth,
                numberIconHeight,
              );
            }
          } else if (index === 2 && dataset.length > 2) {
            const current = dataset[index];
            const top = current.y + numberIconTop;
            if (numberIconHeight < yAxisBottom - current.y) {
              drawBarIcon(
                ctx,
                ThirdIcon,
                xAxis.getPixelForTick(index) - numberIconLeft,
                top,
                numberIconWidth,
                numberIconHeight,
              );
            }
          }
        });
      },
    },
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltips: {
      position: 'myCustomPositioner' as 'bottom',
    },
    scales: {
      xAxis: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 30,
          fontSize: 9,
          weight: 500,
          lineHeight: 12,
        },
        border: {
          width: 3,
          color: breakpoint === 'desktop' ? '#ffffff' : '#F3F4F6',
        },
      },
      yAxis: {
        grid: {
          display: true,
          color: breakpoint === 'desktop' ? '#ffffff' : '#F3F4F6',
          lineWidth: 3,
        },
        ticks: {
          beginAtZero: true,
          padding: 0,
          fontSize: 9,
          weight: 500,
          lineHeight: 12,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: dataMostActiveCards?.map((item: any) => item.count),
        backgroundColor: [
          '#AB77FF',
          '#CFB1FF',
          '#E1CEFF',
          '#D1D5DB',
          '#D1D5DB',
          '#D1D5DB',
        ],
        barThickness: breakpoint === 'desktop' ? 32 : 25,
        borderRadius: 6,
      },
    ],
  };

  const handleClickBar = (
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
  ) => {
    const canvas = document.querySelector(
      '#graph-bar-canvas',
    ) as HTMLCanvasElement;
    if (!canvas) {
      return;
    }
    const rect = canvas.getBoundingClientRect();
    const profiles = canvasProfileImagesSnapshot.slice(0, LIMIT_PROFILES);

    const x = e.clientX - rect.x;
    const y = e.clientY - rect.y;
    const clickedProfile = getCanvasProfiles(x, y, profiles);
    if (!clickedProfile || !clickedProfile?.profile?.profileHash) {
      return;
    }

    navigate(`/view-profile/${clickedProfile.profile.profileHash}`);
  };

  return (
    <ColumnChartContainer>
      <Bar
        id="graph-bar-canvas"
        onClick={handleClickBar}
        plugins={plugin as any}
        options={options}
        data={data}
      />
    </ColumnChartContainer>
  );
}
