import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Blurhash } from 'react-blurhash';

import { clsx } from 'clsx';

const IMAGE_SIZE = 93; // Constant for image dimensions
const RESIZED_IMAGE_SIZE = IMAGE_SIZE * 2; // 2x the display size for better quality
const LOGO_SIZE = 24;

const processImage = async (imgSrc: string): Promise<string> => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Canvas context is not available');
  }

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      // Calculate dimensions once
      const scale = Math.min(
        RESIZED_IMAGE_SIZE / img.width,
        RESIZED_IMAGE_SIZE / img.height,
      );
      const width = img.width * scale;
      const height = img.height * scale;
      const circleRadius = Math.min(width, height) / 2;

      // Set canvas size
      canvas.width = width;
      canvas.height = height;

      // Enable image smoothing for better quality
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      // Draw and clip in one go
      ctx.save();
      ctx.beginPath();
      ctx.arc(width / 2, height / 2, circleRadius, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.clip();

      ctx.drawImage(img, 0, 0, width, height);
      ctx.restore();

      resolve(canvas.toDataURL('image/png', 0.9)); // Slightly compress for better performance
    };

    img.onerror = () => reject(new Error('Failed to load image'));
    img.src = imgSrc;
  });
};

interface ProfileImageProps {
  profilePageUrl: string;
  imgSrc?: string | null;
  blurHash?: string | null;
  logoImgSrc?: string | null;
  sampleMode?: boolean;
}

export const ProfileImage: React.FC<ProfileImageProps> = ({
  profilePageUrl,
  imgSrc,
  blurHash,
  logoImgSrc,
  sampleMode = false,
}) => {
  const [showBlurHash, setShowBlurHash] = useState(true);
  const [profileImage, setProfileImage] = useState<string | null>(null);

  // useEffect(() => {
  //   if (!imgSrc) return;

  //   let isMounted = true;

  //   const loadImage = async () => {
  //     try {
  //       const base64Image = await processImage(imgSrc);
  //       if (isMounted) {
  //         setProfileImage(base64Image);
  //       }
  //     } catch (error) {
  //       console.error('Error processing image:', error);
  //     }
  //   };

  //   loadImage();

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [imgSrc]);

  const imageStyles = useMemo(
    () => ({
      display: 'block',
      height: `${IMAGE_SIZE}px`,
      width: `${IMAGE_SIZE}px`,
    }),
    [],
  );

  if (!imgSrc && !logoImgSrc) return null;

  const renderImageWithLogo = useCallback(() => {
    if (imgSrc) {
      return (
        <div className="relative">
          <div className="rounded-full overflow-hidden">
            {blurHash && showBlurHash && (
              <Blurhash
                hash={blurHash}
                width={IMAGE_SIZE}
                height={IMAGE_SIZE}
              />
            )}
            <img
              src={imgSrc}
              alt="Profile Image"
              style={imageStyles}
              height={IMAGE_SIZE}
              width={IMAGE_SIZE}
              onLoad={() => setShowBlurHash(false)}
            />
          </div>
          {logoImgSrc && (
            <img
              src={logoImgSrc}
              alt="Company Logo"
              style={{
                position: 'absolute',
                bottom: '0',
                right: '0',
                width: `${LOGO_SIZE}px`,
                height: `${LOGO_SIZE}px`,
                borderRadius: '50%',
              }}
            />
          )}
        </div>
      );
    }

    if (!imgSrc && logoImgSrc) {
      <div className="rounded-full overflow-hidden" style={imageStyles}>
        {blurHash && showBlurHash && (
          <Blurhash hash={blurHash} width={IMAGE_SIZE} height={IMAGE_SIZE} />
        )}
        <img
          src={logoImgSrc}
          alt="Company Logo"
          style={imageStyles}
          height={IMAGE_SIZE}
          width={IMAGE_SIZE}
          onLoad={() => setShowBlurHash(false)}
        />
      </div>;
    }

    return;
  }, [imgSrc, logoImgSrc, blurHash, showBlurHash]);

  return (
    <td style={{ paddingRight: '16px', width: '108px' }} valign="top">
      <a
        href={profilePageUrl}
        style={{ textDecoration: 'none' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {sampleMode && renderImageWithLogo()}
        {!sampleMode && imgSrc && (
          <div
            className={clsx(
              'overflow-hidden',
              showBlurHash ? 'rounded-full' : '',
            )}
            style={imageStyles}
          >
            {blurHash && showBlurHash && (
              <Blurhash
                hash={blurHash}
                width={IMAGE_SIZE}
                height={IMAGE_SIZE}
              />
            )}
            <img
              src={imgSrc}
              alt="Profile Image"
              style={imageStyles}
              height={IMAGE_SIZE}
              width={IMAGE_SIZE}
              onLoad={() => setShowBlurHash(false)}
            />
          </div>
        )}
      </a>
    </td>
  );
};
