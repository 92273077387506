import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { EyeIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useCopyToClipboard } from 'usehooks-ts';

import cardsAPI from '@/api/cards';
import Button, { BUTTON_KIND } from '@/components/Button';
import Copy from '@/components/Icons/Copy';
import Shop from '@/components/Icons/Shop';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import InputCheckbox from '@/components/InputCheckbox';
import LoadingAnimation from '@/components/LoadingAnimation';
import Pagination from '@/components/Pagination';
import {
  DeletedProfileCell,
  ProfileInfoCell,
} from '@/components/ProfileListItem';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import { gray } from '@/constants/colors';
import MESSAGES from '@/constants/messages-en';
import { getVariantFullName } from '@/helpers/products';
import { getFullName, hashTruncate } from '@/helpers/strings';
import useAuth from '@/hooks/useAuth';
import useShopify from '@/hooks/useShopify';
import { ICardUser } from '@/types/ICard';

const sortOptions = [
  {
    sort: 'date',
    order: 'desc',
    label: 'Date created (Newest first)',
  },
  {
    sort: 'date',
    order: 'asc',
    label: 'Date created (Oldest first)',
  },
  {
    sort: 'first_name',
    order: 'asc',
    label: 'Name (A-Z)',
  },
  {
    sort: 'first_name',
    order: 'desc',
    label: 'Name (Z-A)',
  },
];

export function ReplacementProducts() {
  const { orgID } = useAuth();
  const { taptReplacement, proceedToCheckout } = useShopify();

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [order, setOrder] = useState('desc');
  const [sort, setSort] = useState('date');
  const [selectedCards, setSelectedCards] = useState(new Set<ICardUser>());
  const [selectAll, setSelectAll] = useState(false);

  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const { data: cards } = useQuery(
    ['listCards', orgID, search, page, pageSize, sort, order],
    () => listCards({ sort, order, page, pageSize, search }),
    {
      enabled: orgID !== undefined,
    },
  );

  async function listCards({
    page,
    pageSize,
    sort,
    order,
    search,
  }: {
    page: number;
    pageSize: number;
    sort: string;
    order: string;
    search: string;
  }) {
    const res = await cardsAPI.listCards({
      orgID,
      sort,
      order,
      page,
      pageSize,
      search,
    });

    return res.data;
  }

  async function checkItem(card: ICardUser) {
    let arr = Array.from(selectedCards);

    if (selectedCards.has(card)) {
      arr = arr.filter(e => e !== card);
    } else {
      arr = [...selectedCards, card];
    }

    setSelectedCards(new Set(arr));
  }

  async function goToCheckout(cards: ICardUser[]) {
    if (taptReplacement === undefined) {
      return;
    }

    const attributes = cards.map(card => ({
      first_name: card.profile.first_name,
      last_name: card.profile.last_name,
      url: `${window.location.origin}/view-profile/${card.profile.profile_hash}`,
    }));

    proceedToCheckout(taptReplacement.variants[0].id, cards.length, [
      {
        key: '_cardProfiles',
        value: JSON.stringify(attributes),
      },
    ]);
  }

  return (
    <>
      <div className="space-y-8 pt-8">
        <div className="flex justify-end space-x-3">
          <Search
            id={`ReplacementProducts-${page}-${pageSize}-${sort}-${order}-active-${search}`}
            search={search}
            setSearch={setSearch}
            fetchQuery={searchQuery =>
              listCards({ page, pageSize, sort, order, search: searchQuery })
            }
          />
          <Sort
            id={`ReplacementProducts-${page}-${pageSize}-${sort}-${order}-active-${search}`}
            options={sortOptions}
            sort={sort}
            order={order}
            setSort={setSort}
            setOrder={setOrder}
            fetchQuery={(sortQuery, orderQuery) =>
              listCards({
                page,
                pageSize,
                search,
                sort: sortQuery,
                order: orderQuery,
              })
            }
          />
        </div>
        {cards ? (
          cards.data.length > 0 ? (
            <>
              <div className="hidden xl:block relative border border-solid rounded-lg overflow-hidden">
                <table
                  className={clsx('w-full max-w-full table-fixed', {
                    'opacity-40': isPaginationLoading,
                  })}
                >
                  <thead className="bg-gray-100 border-b border-gray-200">
                    <tr className="uppercase text-gray-900 text-sm">
                      <th scope="col" className="px-4 py-3 w-[5%]">
                        <InputCheckbox
                          id="select-all-replacement-products-page"
                          value={selectAll}
                          // label has a margin -_-, which causes problems when there is no label
                          labelClassName=""
                          onChange={value => {
                            setSelectAll(value);

                            const validCards = cards.data.filter(
                              card => card.profile.status !== 'deleted',
                            );
                            if (value) {
                              setSelectedCards(new Set(validCards));
                            } else {
                              setSelectedCards(new Set());
                            }
                          }}
                        />
                      </th>
                      <th
                        scope="col"
                        className="font-medium p-3 text-left w-1/4"
                      >
                        Linked product &amp; id
                      </th>
                      <th
                        scope="col"
                        className="font-medium p-3 text-left w-1/3"
                      >
                        Name &amp; profile id
                      </th>
                      <th scope="col" className="font-medium p-3">
                        View profile
                      </th>
                      <th scope="col" className="font-medium p-3 px-4">
                        Reorder
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {cards.data.map((card, index) => (
                      <CardListItem
                        key={index}
                        card={card}
                        selected={selectedCards.has(card)}
                        checkRow={() => checkItem(card)}
                        reorder={() => goToCheckout([card])}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="xl:hidden space-y-4">
                <div className="bg-gray-100 flex xl:hidden flex-row border border-gray-300 rounded-md divide-x divide-gray-300">
                  <div className="pl-6 md:px-6 py-4">
                    <InputCheckbox
                      id="select-all"
                      value={selectAll}
                      onChange={value => {
                        setSelectAll(value);

                        const validCards = cards.data.filter(
                          card => card.profile.status !== 'deleted',
                        );
                        if (value) {
                          setSelectedCards(new Set(validCards));
                        } else {
                          setSelectedCards(new Set());
                        }
                      }}
                    />
                  </div>
                  <div className="font-medium pl-4 md:px-6 py-4 uppercase">
                    Name & profile id, linked product & id, reorder
                  </div>
                </div>
                {cards.data.map((card, index) => (
                  <CardListItemMobile
                    key={index}
                    card={card}
                    selected={selectedCards.has(card)}
                    checkRow={() => checkItem(card)}
                    reorder={() => goToCheckout([card])}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="py-32">
              <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                {MESSAGES.profile.list.empty.heading}
              </h3>
              <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                {MESSAGES.profile.list.empty.description}
              </p>
            </div>
          )
        ) : (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
          </div>
        )}
        <Pagination
          id={`ReplacementProducts-${page}-${pageSize}-${sort}-${order}-active-${search}`}
          className="bg-gray-50"
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          fetchQuery={(pageQuery, pageSizeQuery) =>
            listCards({
              sort,
              order,
              page: pageQuery,
              pageSize: pageSizeQuery,
              search,
            })
          }
          setIsLoading={setIsPaginationLoading}
        />
      </div>
      <InfoPanelFooter
        className={clsx({
          '!-bottom-28': selectedCards.size < 2,
        })}
      >
        <div className="flex items-center space-x-3 justify-end">
          <span className="text-gray-500 text-sm">
            {selectedCards.size} selected
          </span>
          <Button
            buttonText="Reorder in bulk"
            onClick={() => goToCheckout(Array.from(selectedCards))}
          />
        </div>
      </InfoPanelFooter>
    </>
  );
}

type ProfileListItemProps = {
  card: ICardUser;
  selected: boolean;
  checkRow: () => void;
  reorder: () => void;
};

function CardListItem({
  card,
  checkRow,
  selected,
  reorder,
}: ProfileListItemProps) {
  const { profile, card_hash: cardHash, type } = card;
  const {
    profile_hash: profileHash,
    first_name: firstName,
    last_name: lastName,
    photo,
    status,
  } = profile;
  const photoUrl = photo?.thumb_url;
  const isDeleted = status === 'deleted';

  const [, copy] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  const fullName = getFullName({
    firstName,
    middleName: null,
    lastName,
  });

  async function copyHashToClipboard() {
    await copy(cardHash);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }

  return (
    <tr
      className={`hover:bg-gray-50 relative ${
        isDeleted ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      onClick={isDeleted ? undefined : checkRow}
    >
      <td className="whitespace-nowrap py-3 px-4">
        {!isDeleted && (
          <InputCheckbox
            id={`select-reorder-${profileHash}`}
            value={selected}
            onChange={checkRow}
            labelClassName=""
          />
        )}
      </td>
      <td className="whitespace-nowrap text-sm p-3">
        <span className="flex flex-col text-sm">
          <span className="text-gray-900 font-medium truncate">
            {getVariantFullName(type)}
          </span>
          <span className="flex text-gray-500">
            ID:&nbsp;
            <button
              className="flex items-center text-brand-600 z-10 font-medium"
              onClick={e => {
                e.stopPropagation();
                copyHashToClipboard();
              }}
            >
              {isCopied ? 'Copied!' : hashTruncate(cardHash)}
              <Copy className="w-4 h-4" />
            </button>
          </span>
        </span>
      </td>
      <td className="whitespace-nowrap text-sm p-3">
        {isDeleted ? (
          <DeletedProfileCell
            fullName={fullName}
            message="Assign to a different profile to reorder product"
          />
        ) : (
          <ProfileInfoCell
            profileHash={profileHash}
            fullName={fullName}
            photoUrl={photoUrl}
          />
        )}
      </td>
      <td className="whitespace-nowrap p-3">
        {!isDeleted && (
          <span className="flex justify-center">
            <Link to={`/view-profile/${profileHash}`}>
              <EyeIcon
                className="w-5 h-5"
                color={gray['900']}
                strokeWidth={2}
              />
            </Link>
          </span>
        )}
      </td>
      <td className="whitespace-nowrap">
        {!isDeleted && (
          <span className="flex justify-center">
            <Button
              buttonText="Reorder"
              kind={BUTTON_KIND.WHITE}
              onClick={e => {
                e.stopPropagation();
                reorder();
              }}
            />
          </span>
        )}
      </td>
    </tr>
  );
}

function CardListItemMobile({
  card,
  selected,
  checkRow,
  reorder,
}: ProfileListItemProps) {
  const { profile, card_hash: cardHash, type } = card;
  const {
    profile_hash: profileHash,
    first_name: firstName,
    last_name: lastName,
    photo,
    status,
  } = profile;
  const photoUrl = photo?.thumb_url;
  const isDeleted = status === 'deleted';

  const [, copy] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  async function copyHashToClipboard() {
    await copy(cardHash);

    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  }

  const fullName = getFullName({
    firstName,
    middleName: null,
    lastName,
  });

  return (
    <div className="border border-gray-300 bg-white rounded-md flex flex-col divide-y divide-gray-300">
      <div className="flex flex-row items-center justify-between px-6 py-4">
        {isDeleted ? (
          <span className="w-7" />
        ) : (
          <span className="self-start">
            <InputCheckbox
              id={`select-profile-${profileHash}`}
              value={selected}
              onChange={checkRow}
            />
          </span>
        )}
        <div className="flex flex-col w-full space-y-3">
          <div className="flex text-sm justify-between">
            <span className="font-medium text-gray-900">
              {getVariantFullName(type)}
            </span>
            <span className="flex text-gray-500">
              ID:&nbsp;
              <button
                className="flex items-center text-brand-600 z-10 font-medium"
                onClick={e => {
                  e.stopPropagation();
                  copyHashToClipboard();
                }}
              >
                {isCopied ? 'Copied!' : hashTruncate(cardHash)}
                <Copy className="w-4 h-4" />
              </button>
            </span>
          </div>
          {isDeleted ? (
            <DeletedProfileCell
              fullName={fullName}
              message="Assign to a different profile to reorder product"
            />
          ) : (
            <ProfileInfoCell
              fullName={fullName}
              profileHash={profileHash}
              photoUrl={photoUrl}
            />
          )}
        </div>
      </div>
      {!isDeleted && (
        <div className="flex divide-x divide-gray-300">
          <button
            className="flex items-center justify-center p-3 w-1/2 space-x-3 text-sm font-medium"
            onClick={reorder}
          >
            <Shop className="w-5 h-5 text-gray-500" />
            <span className="text-gray-700">Reorder</span>
          </button>
          <Link
            to={`/view-profile/${profileHash}`}
            className="flex items-center justify-center p-3 w-1/2 space-x-3 text-sm font-medium"
          >
            <EyeIcon className="w-5 h-5 text-gray-500" strokeWidth={2} />
            <span className="text-gray-700">View profile</span>
          </Link>
        </div>
      )}
    </div>
  );
}
