import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  EyeIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import { useCopyToClipboard } from 'usehooks-ts';

import profilesAPI from '@/api/profiles';
import { ReactComponent as BlankProfileAvatar } from '@/assets/svg/default_avatar.svg';
import Button, { BUTTON_ICON_POSITION, BUTTON_KIND } from '@/components/Button';
import Copy from '@/components/Icons/Copy';
import InputCheckbox from '@/components/InputCheckbox';
import { gray } from '@/constants/colors';
import MESSAGES from '@/constants/messages-en';
import { getFullName, hashTruncate } from '@/helpers/strings';
import { validateEmail } from '@/helpers/validate';
import useAuth from '@/hooks/useAuth';
import { IProfileAppAccess, IProfileBase } from '@/types/IProfile';

import { Alert } from './Alert';
import MobileDeviceIconGreen from './Icons/MobileDeviceIconGreen';
import MobileDeviceIconRed from './Icons/MobileDeviceIconRed';
import MobileDeviceIconYellow from './Icons/MobileDeviceIconYellow';
import Spinner from './Icons/Spinner';
import Modal from './Modals/Modal';
import { MoreOptionsMenu } from './ProfilesListPage/MoreOptions';

function EditProfileWarningMobile() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dialogTitle="Please design cards before editing profiles."
        dialogDescription="Profile editing is disabled until you submit your card design."
        cancelButtonText="Close"
        onSuccess={() => {}}
      />
      <button
        type="button"
        className="flex flex-row items-center justify-center p-4 text-sm font-medium text-gray-700 w-1/2"
        onClick={() => setIsOpen(true)}
      >
        <PencilIcon
          className="w-5 h-5 text-gray-900"
          aria-hidden="true"
          strokeWidth={2}
        />
        <span className="ml-2">Edit profile</span>
      </button>
    </>
  );
}

const AppAccessIcon = {
  [IProfileAppAccess.pending]: <MobileDeviceIconYellow />,
  [IProfileAppAccess.connected]: <MobileDeviceIconGreen />,
  [IProfileAppAccess.not_connected]: <MobileDeviceIconRed />,
} as const;

export type ProfileListRowProps = {
  profile: IProfileBase;
  setActionResult: React.Dispatch<React.SetStateAction<Alert | undefined>>;
  selected: boolean;
  checkRow: () => void;
  refreshProfiles: () => void;
};

export function ProfileListRowMobile({
  profile,
  setActionResult,
  selected,
  checkRow,
  refreshProfiles,
}: ProfileListRowProps) {
  const {
    id,
    first_name: firstName,
    last_name: lastName,
    profile_hash: profileHash,
    job_title: jobTitle,
    email,
    group_name: groupName,
    group_subheading: groupSubheading,
    editable,
    app_access: appAccess,
  } = profile;
  const photoUrl = profile.photo?.thumb_url;

  const { orgID } = useAuth();
  const [, copy] = useCopyToClipboard();

  const [copied, setCopied] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [isAppInviteOpen, setIsAppInviteOpen] = useState(false);
  const [isResendAppInviteOpen, setIsResendAppInviteOpen] = useState(false);

  const editProfile = `/edit-profile/${id}`;
  const viewProfile = `/view-profile/${profileHash}`;

  async function copyHashToClipboard() {
    await copy(profileHash);

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  async function inviteToApp() {
    if (orgID === undefined) {
      return;
    }

    try {
      if (!validateEmail(email)) {
        setActionResult({
          outcome: 'error',
          message: MESSAGES.error.appInviteError,
        });
        return;
      }

      setIsInviting(true);

      await profilesAPI.inviteUsers(orgID, [id]);

      setActionResult({
        outcome: 'success',
        message: 'App invite successfully sent',
      });
    } catch (error: unknown) {
      setActionResult({ outcome: 'error', message: MESSAGES.error.generic });
    } finally {
      setIsAppInviteOpen(false);
      setIsResendAppInviteOpen(false);
      setIsInviting(false);
    }
  }

  return (
    <div className="border border-gray-300 bg-white rounded-md flex flex-col divide-y divide-gray-300">
      <div className="flex flex-row items-center justify-between px-6 py-4">
        <span className="self-start">
          <InputCheckbox
            id={`select-profile-${profileHash}`}
            value={selected}
            onChange={checkRow}
          />
        </span>
        <div className="flex flex-col w-3/4 px-2">
          <div className="flex flex-row space-x-3">
            <div className="flex-shrink-0">
              {photoUrl ? (
                <img
                  className="w-10 h-10 rounded-full"
                  src={photoUrl}
                  alt="Profile photo"
                />
              ) : (
                <BlankProfileAvatar className="rounded-full" />
              )}
            </div>
            <div className="flex flex-col flex-grow min-w-0">
              <span className="truncate font-medium">
                {firstName} {lastName}
              </span>
              <span className="truncate flex flex-row items-center text-gray-500">
                ID:&nbsp;
                <button
                  type="button"
                  className="appearance-none text-brand-600 text-sm font-medium items-center flex flex-row"
                  onClick={copyHashToClipboard}
                >
                  {copied ? 'Copied!' : hashTruncate(profileHash)}
                  <Copy className="w-4 h-4 ml-1" />
                </button>
              </span>
            </div>
          </div>
          {jobTitle && (
            <span className="truncate font-medium pt-2">{jobTitle}</span>
          )}
          {email && <span className="text-gray-500 truncate">{email}</span>}
          {groupName && (
            <span className="truncate font-medium pt-2">{groupName}</span>
          )}
          {groupSubheading && (
            <span className="text-gray-500 truncate">{groupSubheading}</span>
          )}
        </div>
        <span className="self-start">
          {isInviting ? (
            <Spinner />
          ) : (
            <Button
              className="border-none disabled:!opacity-100"
              kind={BUTTON_KIND.COLOR_OMITTED}
              size="xsmall"
              icon={AppAccessIcon[appAccess]}
              onClick={
                appAccess === IProfileAppAccess.pending
                  ? () => setIsResendAppInviteOpen(true)
                  : () => setIsAppInviteOpen(true)
              }
              disabled={appAccess === IProfileAppAccess.connected}
            />
          )}
        </span>
      </div>
      <div className="flex flex-row divide-x divide-gray-300">
        {editable ? (
          <Link
            to={editProfile}
            className="flex flex-row items-center justify-center p-4 text-sm font-medium text-gray-700 w-1/2"
          >
            <PencilIcon className="w-5 h-5 text-gray-900" aria-hidden="true" />
            <span className="ml-2">Edit profile</span>
          </Link>
        ) : (
          <EditProfileWarningMobile />
        )}
        <Link
          to={viewProfile}
          className="flex flex-row items-center justify-center p-4 text-sm font-medium text-gray-700 w-1/2"
        >
          <EyeIcon className="w-5 h-5 text-gray-900" aria-hidden="true" />
          <span className="ml-2">View profile</span>
        </Link>
        <div className="flex items-center px-2">
          <MoreOptionsMenu
            profile={profile}
            refreshProfiles={refreshProfiles}
            setActionResult={setActionResult}
          />
        </div>
      </div>
      <Modal
        isOpen={isAppInviteOpen}
        setIsOpen={setIsAppInviteOpen}
        dialogTitle={MESSAGES.profile.appInvite.send.heading}
        dialogDescription={MESSAGES.profile.appInvite.send.description}
        onSuccess={inviteToApp}
        successButtonText="Send invite"
        isLoading={isInviting}
      />
      <Modal
        isOpen={isResendAppInviteOpen}
        setIsOpen={setIsResendAppInviteOpen}
        onSuccess={inviteToApp}
        dialogTitle={MESSAGES.profile.appInvite.resend.heading}
        dialogDescription={MESSAGES.profile.appInvite.resend.description}
        successButtonText="Send invite"
        isLoading={isInviting}
      />
    </div>
  );
}

export function ProfileListRow({
  profile,
  setActionResult,
  selected,
  checkRow,
  refreshProfiles,
}: ProfileListRowProps) {
  const {
    id,
    email,
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    profile_hash: profileHash,
    job_title: jobTitle,
    group_name: groupName,
    app_access: appAccess,
    group_subheading: groupSubheading,
    editable,
  } = profile;
  const photoUrl = profile.photo?.thumb_url;

  const { orgID } = useAuth();

  const [isInviting, setIsInviting] = useState(false);
  const [isAppInviteOpen, setIsAppInviteOpen] = useState(false);
  const [isResendAppInviteOpen, setIsResendAppInviteOpen] = useState(false);

  const viewProfile = `/view-profile/${profileHash}`;
  const editProfile = `/edit-profile/${id}`;

  async function inviteToApp() {
    if (orgID === undefined) {
      return;
    }

    try {
      if (!validateEmail(email)) {
        setActionResult({
          outcome: 'error',
          message: MESSAGES.error.appInviteError,
        });
        return;
      }

      setIsInviting(true);

      await profilesAPI.inviteUsers(orgID, [profile.id]);

      setActionResult({
        outcome: 'success',
        message: 'App invite successfully sent',
      });
      refreshProfiles();
    } catch (error: unknown) {
      setActionResult({ outcome: 'error', message: MESSAGES.error.generic });
    } finally {
      setIsAppInviteOpen(false);
      setIsResendAppInviteOpen(false);
      setIsInviting(false);
    }
  }

  const fullName = getFullName({ firstName, lastName, middleName });

  return (
    <>
      <tr
        className="hover:bg-gray-50 relative cursor-pointer"
        onClick={checkRow}
      >
        <td className="whitespace-nowrap py-3 px-4">
          <InputCheckbox
            id={`select-profile-${profileHash}`}
            labelClassName=""
            value={selected}
            onChange={checkRow}
          />
        </td>
        <td className="whitespace-nowrap text-sm text-gray-900 p-3">
          <ProfileInfoCell
            profileHash={profileHash}
            fullName={fullName}
            photoUrl={photoUrl}
          />
        </td>
        <td className="whitespace-nowrap text-sm text-gray-900 p-3">
          <span className="flex flex-col min-w-0">
            <span className="text-gray-900 truncate">{jobTitle}</span>
            <span className="text-gray-500 truncate">{email}</span>
          </span>
        </td>
        <td className="whitespace-nowrap text-sm font-medium text-gray-900 p-3">
          <span className="flex flex-col text-gray-900 min-w-0">
            <span className="truncate">{groupName}</span>
            <span className="font-normal text-gray-500 truncate">
              {groupSubheading}
            </span>
          </span>
        </td>
        <td className="whitespace-nowrap text-sm text-gray-900 flex justify-center p-3">
          {isInviting ? (
            <span className="py-[11px]">
              <Spinner />
            </span>
          ) : (
            <Button
              className="disabled:!opacity-60"
              kind={BUTTON_KIND.COLOR_OMITTED}
              size="xsmall"
              icon={AppAccessIcon[appAccess]}
              disabled={appAccess === IProfileAppAccess.connected}
              onClick={
                appAccess === IProfileAppAccess.pending
                  ? () => setIsResendAppInviteOpen(true)
                  : () => setIsAppInviteOpen(true)
              }
            />
          )}
        </td>
        <td className="whitespace-nowrap text-sm text-gray-900 group">
          <span className="flex items-center justify-center tooltipcode">
            <Button
              kind={BUTTON_KIND.COLOR_OMITTED}
              size="xsmall"
              icon={<PencilIcon color="#111827" strokeWidth={1.8} />}
              href={editable ? editProfile : undefined}
              disabled={!editable}
            />
            {!editable && (
              <div className="hidden absolute z-100 -translate-x-[58%] bg-brand-100 tooltipcontent group-hover:flex p-3 rounded-md text-brand-900 flex-row items-center shadow-lg max-h-16 w-max">
                <div className="flex flex-row gap-2">
                  <div className="flex-shrink-0">
                    <ExclamationCircleIcon className="w-5 h-5 text-brand-500" />
                  </div>
                  <div className="text-sm space-y-1">
                    <div className="font-medium">
                      Please design cards before editing profiles.
                    </div>
                    <div>
                      Profile editing is disabled until you submit your card
                      design.
                    </div>
                  </div>
                </div>
                <div className="w-10 h-10 -mr-4 rotate-45 bg-brand-100" />
              </div>
            )}
          </span>
        </td>
        <td className="whitespace-nowrap text-sm text-gray-900 p-3">
          <span className="flex justify-center">
            <Button
              kind={BUTTON_KIND.COLOR_OMITTED}
              icon={<EyeIcon className="text-gray-900" strokeWidth={1.8} />}
              href={viewProfile}
              iconPos={BUTTON_ICON_POSITION.CENTER}
              external
            />
          </span>
        </td>
        <td className="whitespace-nowrap text-sm text-center py-3 px-4">
          <span className="flex flex-1 items-center justify-center">
            <MoreOptionsMenu
              profile={profile}
              refreshProfiles={refreshProfiles}
              setActionResult={setActionResult}
            />
          </span>
        </td>
      </tr>
      <Modal
        isOpen={isAppInviteOpen}
        setIsOpen={setIsAppInviteOpen}
        dialogTitle={MESSAGES.profile.appInvite.send.heading}
        dialogDescription={MESSAGES.profile.appInvite.send.description}
        onSuccess={inviteToApp}
        successButtonText="Send invite"
        isLoading={isInviting}
      />
      <Modal
        isOpen={isResendAppInviteOpen}
        setIsOpen={setIsResendAppInviteOpen}
        onSuccess={inviteToApp}
        dialogTitle={MESSAGES.profile.appInvite.resend.heading}
        dialogDescription={MESSAGES.profile.appInvite.resend.description}
        successButtonText="Send invite"
        isLoading={isInviting}
      />
    </>
  );
}

type ProfileInfoCellProps = {
  fullName: string | null;
  profileHash: string;
  photoUrl: string | undefined;
};

export function ProfileInfoCell({
  fullName,
  profileHash,
  photoUrl,
}: ProfileInfoCellProps) {
  const [, copy] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  async function copyHashToClipboard() {
    await copy(profileHash);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }

  return (
    <span className="flex space-x-2">
      <span className="flex-shrink-0">
        {photoUrl ? (
          <img
            className="w-10 h-10 rounded-full"
            src={photoUrl}
            alt="Profile photo"
          />
        ) : (
          <BlankProfileAvatar className="rounded-full" />
        )}
      </span>
      <span className="flex flex-col min-w-0">
        <span className="font-medium text-gray-900 truncate text-sm">
          {fullName}
        </span>
        <span className="text-sm flex text-gray-500">
          ID:&nbsp;
          <button
            className="flex items-center text-brand-600 z-10 font-medium"
            onClick={e => {
              e.stopPropagation();
              copyHashToClipboard();
            }}
            disabled={isCopied}
          >
            {isCopied ? 'Copied!' : hashTruncate(profileHash)}
            <Copy className="w-4 h-4" />
          </button>
        </span>
      </span>
    </span>
  );
}

type DeletedProfileCellProps = Pick<ProfileInfoCellProps, 'fullName'> & {
  message: string;
};

export function DeletedProfileCell({
  fullName,
  message,
}: DeletedProfileCellProps) {
  return (
    <div className="flex space-x-2">
      <div className="w-10 h-10 bg-red-400 flex-shrink-0 rounded-full flex items-center justify-center">
        <ExclamationTriangleIcon
          width={30}
          height={30}
          color={gray['50']}
          strokeWidth={2}
        />
      </div>
      <div className="text-sm text-red-800">
        <span className="font-medium">Deleted profile: {fullName}</span>
        <div className="text-wrap">{message}</div>
      </div>
    </div>
  );
}
