import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import { SuperuserAnalyticsResponse } from '@/types/IAnalytics';

import AppLogins from './Charts/AppLogins';
import EventsChart from './Charts/Events';
import EventsByGeo from './Charts/EventsByGeo';
import FulfilledOrders from './Charts/FulfilledOrders';
import GrowthRate from './Charts/GrowthRate';
import InactiveProfiles from './Charts/InactiveProfiles';
import NumberOfTaps from './Charts/NumberOfTaps';
import OrgLogins from './Charts/OrgLogins';
import ProfileEdits from './Charts/ProfileEdits';
import ProfileTypes from './Charts/ProfileTypes';
import TopReferrers from './Charts/TopReferrers';
import DateFilter from './DateFilter';
import GroupFilter from './GroupFilter';
import type { Props as GroupFilterProps } from './GroupFilter';
import ProfileFilter from './ProfileFilter';
import type { Props as ProfileFilterProps } from './ProfileFilter';
import './react-datepicker.css';

type SuperuserAnalyticsDashboardProps = {
  data: SuperuserAnalyticsResponse;
  refetchData?: (
    from?: string,
    to?: string,
    groupIds?: number[],
    profileIds?: number[],
  ) => Promise<void>;
  listProfiles: ProfileFilterProps['listProfiles'];
  listGroups: GroupFilterProps['listGroups'];
};

function SuperuserAnalyticsDashboard({
  data: {
    profile_views: profileViews,
    contacts_sent: contactsSent,
    contacts_received: contactsReceived,
    profile_types: profileTypes,
    top_referrers: topReferrers,
    profile_edits: profileEdits,
    events_by_geo: eventsByGeo,
    growth_rate: growthRate,
    number_of_taps: numberOfTaps,
    app_logins: appLogins,
    organisation_logins: organisationLogins,
    inactive_in_previous_two_weeks_profiles: inactiveProfiles,
    fulfilled_orders: fulfilledOrders,
  },
  refetchData,
  listProfiles,
  listGroups,
}: SuperuserAnalyticsDashboardProps) {
  const [from, setFrom] = useState<string | undefined>();
  const [to, setTo] = useState<string | undefined>();
  const [groupIds, setGroupIds] = useState<number[]>([]);
  const [profileIds, setProfileIds] = useState<number[]>();

  return (
    <div className="mb-8 md:mb-0">
      <div className="-mt-20 mb-10 flex justify-end flex-col items-end">
        <div className="grid gap-2 grid-flow-col">
          <ProfileFilter
            fetchData={ids => {
              setProfileIds(ids);
              refetchData?.(from, to, groupIds, ids);
            }}
            listProfiles={listProfiles}
          />
          <GroupFilter
            fetchData={ids => {
              setGroupIds(ids);
              refetchData?.(from, to, ids, profileIds);
            }}
            listGroups={listGroups}
          />
          <DateFilter
            fetchData={(fromDate, toDate) => {
              setFrom(fromDate);
              setTo(toDate);
              refetchData?.(fromDate, toDate, groupIds, profileIds);
            }}
          />
        </div>
      </div>
      <div className="bg-white rounded-lg p-4 md:p-8 mt-2 md:mt-0">
        <h2 className="text-gray-900 font-poppins font-medium text-2xl md:text-2xl">
          Domain Analytics
        </h2>
        <h3 className="text-gray-500 mt-2 hidden md:show">
          Profiles with the most activity (profile views, contacts sent and
          contacts received)
        </h3>
        <hr className="h-px mt-4 mb-8 bg-gray-200 border-0 " />
        <div className="grid md:grid-cols-4 gap-8 md:gap-10 w-full">
          <div>
            <EventsChart
              title="Total profile views"
              total={profileViews.total}
              percent={profileViews.change}
              data={{
                labels: Object.keys(profileViews.entries),
                series: [
                  { name: 'Views', data: Object.values(profileViews.entries) },
                ],
              }}
              dateFilter={{ from, to }}
            />
          </div>
          <div>
            <EventsChart
              title="Total contacts sent"
              total={contactsSent.total}
              percent={contactsSent.change}
              data={{
                labels: Object.keys(contactsSent.entries),
                series: [
                  { name: 'Views', data: Object.values(contactsSent.entries) },
                ],
              }}
              dateFilter={{ from, to }}
            />
          </div>
          <div>
            <EventsChart
              title="Total contacts received"
              total={contactsReceived.total}
              percent={contactsReceived.change}
              data={{
                labels: Object.keys(contactsReceived.entries),
                series: [
                  {
                    name: 'Views',
                    data: Object.values(contactsReceived.entries),
                  },
                ],
              }}
              dateFilter={{ from, to }}
            />
          </div>
          <ProfileTypes data={profileTypes} />
        </div>
      </div>
      <div className="bg-white rounded-lg p-4 md:p-8 md:mt-4 flex col-span-12 md:col-span-12">
        <GrowthRate
          title="Growth Rate"
          subtitle="Number of users who have joined tapt every month"
          data={growthRate}
        />
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div className="bg-white rounded-lg p-4 md:p-8 md:mt-4 flex items-center col-span-4 md:col-span-2">
          <NumberOfTaps
            data={numberOfTaps}
            title="Number of Taps"
            subtitle="The number of taps (connections) people make over time"
          />
        </div>
        <div className="bg-white rounded-lg p-4 md:p-8 md:mt-4 flex items-center col-span-4 md:col-span-2">
          <TopReferrers
            data={topReferrers}
            dateFilter={{ from, to }}
            title="Top Referrers"
            subtitle="Last month website stats"
            mobileSubtitle="Website statistics"
          />
        </div>
      </div>
      <div className="bg-white rounded-lg p-4 md:p-8 md:mt-4 flex col-span-12 md:col-span-12">
        <EventsByGeo
          data={eventsByGeo}
          title="Tap Geolocation"
          subtitle="Location of profile views, contacts sent and contacts received"
          mobileSubtitle="Location of profile views"
        />
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div className="bg-white rounded-lg p-4 md:p-8 md:mt-4 flex col-span-5 md:col-span-2">
          <InactiveProfiles
            data={inactiveProfiles}
            title="Inactive Profiles"
            subtitle="Number of profiles which have not been used in over one month"
          />
        </div>
        <div className="bg-white rounded-lg p-4 md:p-8 md:mt-4 flex col-span-5 md:col-span-2">
          <ProfileEdits
            title="Profile Edits"
            subtitle="Total profile edits"
            data={profileEdits}
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div className="bg-white rounded-lg p-4 md:p-8 md:mt-4 flex items-center col-span-4 md:col-span-2">
          <AppLogins
            data={appLogins}
            title="App Logins"
            subtitle="The number of active users on the app"
          />
        </div>
        <div className="bg-white rounded-lg p-4 md:p-8 md:mt-4 flex items-center col-span-4 md:col-span-2">
          <OrgLogins
            data={organisationLogins}
            title="Organisation Logins"
            subtitle="The number of login's to the dashboard by all organisations"
          />
        </div>
      </div>
      <div className="bg-white rounded-lg p-4 md:p-8 md:mt-4 flex col-span-12 md:col-span-12">
        <FulfilledOrders
          data={fulfilledOrders}
          title="Orders by Designer"
          subtitle="The number of designs uploaded by each designer"
        />
      </div>
    </div>
  );
}

export default SuperuserAnalyticsDashboard;
