import { CSSProperties, JSX, ReactNode } from 'react';

export interface ITableHeader {
  scope?: string;
  className?: string;
  style?: CSSProperties;
  children: ReactNode | string | JSX.Element | any;
}

const TableHeader = ({
  scope = 'col',
  style,
  children,
  className,
}: ITableHeader) => {
  return (
    <th scope={scope} className={className} style={style}>
      {children}
    </th>
  );
};

export default TableHeader;
