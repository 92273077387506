import React from 'react';

import IFile from '@/types/IFile';

interface BannerProps {
  file: IFile;
}

const DISPLAY_SIZE = { width: 262, height: 101 };

export const Banner: React.FC<BannerProps> = ({ file }) => {
  return (
    <td
      style={{
        paddingRight: '16px',
        width: `${DISPLAY_SIZE.width}px`,
        verticalAlign: 'top',
      }}
    >
      <img
        src={file.original_url}
        alt="Banner"
        style={{
          display: 'block',
          // height: `${DISPLAY_SIZE.height}px`,
          // width: `${DISPLAY_SIZE.width}px`,
        }}
        // height={DISPLAY_SIZE.height}
        // width={DISPLAY_SIZE.width}
      />{' '}
    </td>
  );
};
