import { ensureAbsoluteUrl } from '@/helpers/urlHandler';
import { IProfileSocialLink } from '@/types/IProfile';

interface SocialIconProps {
  href: string;
  alt: string;
  imageUrl: string;
}

const socialIconStyles = {
  td: {
    valign: 'middle' as const,
    width: '48px',
    paddingBottom: '16px',
    align: 'right' as const,
  },
  link: {
    textDecoration: 'none',
    color: '#1B3B49',
  },
  strong: {
    textDecoration: 'none',
    fontWeight: 'normal',
    color: '#1B3B49',
  },
  img: {
    display: 'block',
    width: '32px',
    height: '32px',
  },
};

const SocialIcon: React.FC<SocialIconProps> = ({ href, alt, imageUrl }) => (
  <td style={socialIconStyles.td}>
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={socialIconStyles.link}
    >
      <strong style={socialIconStyles.strong}>
        <img
          style={socialIconStyles.img}
          width="32"
          height="32"
          alt={alt}
          src={imageUrl}
        />
      </strong>
    </a>
  </td>
);

// Social Icons Container Component
export const SocialIcons: React.FC<{ socialLinks: IProfileSocialLink[] }> = ({
  socialLinks,
}) => {
  if (socialLinks.length === 0) {
    return null;
  }

  return (
    <tr>
      <td>
        <table cellSpacing="0" cellPadding="0">
          <tr>
            {socialLinks.map((link, index) => {
              try {
                const url = ensureAbsoluteUrl(link.value);
                return !url ? null : (
                  <SocialIcon
                    key={index}
                    href={url}
                    alt={link.type}
                    imageUrl={`https://assets.tapt.io/email/${link.type}.png`}
                  />
                );
              } catch (e) {
                return null;
              }
            })}
          </tr>
        </table>
      </td>
    </tr>
  );
};
