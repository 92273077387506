import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import profilesAPI from '@/api/profiles';
import { EditProfileForm } from '@/components/EditProfilePage/Form';
import Layout from '@/components/Layout';
import LoadingAnimation from '@/components/LoadingAnimation';
import COLORS from '@/constants/colors';
import useAuth from '@/hooks/useAuth';
import { FileSchema } from '@/types/IFile';
import {
  ProfileFileLinkUnionSchema,
  ProfileQuickLinkSchema,
  ProfileSocialLinkSchema,
} from '@/types/TaptSocials';

export default function EditProfilePage() {
  const location = useLocation();
  const [, , profileID] = location.pathname.split('/');

  const { orgID } = useAuth();

  const { data: profile } = useQuery(['showProfile', profileID], showProfile, {
    enabled: orgID !== undefined,
    refetchOnWindowFocus: false,
  });

  const methods = useForm<WorkingProfileForm>({
    resolver: zodResolver(WorkingProfileFormSchema),
  });

  useEffect(() => {
    if (profile) {
      methods.reset({
        city: profile.city,
        company_name: profile.company_name,
        company_phone_number: profile.company_phone_number,
        company_website: profile.company_website,
        country: profile.country,
        contact_exchange_enabled: profile.contact_exchange_enabled,
        custom_design_enabled: false,
        email: profile.email,
        extra_emails: profile.extra_emails,
        extra_mobile_numbers: profile.extra_mobile_numbers,
        extra_company_phone_numbers: profile.extra_company_phone_numbers,
        first_name: profile.first_name,
        job_title: profile.job_title,
        last_name: profile.last_name,
        middle_name: profile.middle_name,
        mobile_number: profile.mobile_number,
        note: profile.note,
        photo: profile.photo,
        post_code: profile.post_code,
        profile_file_links: profile.profile_file_links.map(link => ({
          status: 'complete',
          ...link,
        })),
        profile_quick_links: profile.profile_quick_links,
        profile_social_links: profile.profile_social_links,
        pronouns: profile.pronouns,
        settings: {
          company_logo: null,
          bg_color: COLORS.organisation.defaultProfile.backgroundColor,
          text_color: COLORS.organisation.defaultProfile.textColor,
          button_bg_color:
            COLORS.organisation.defaultProfile.buttonBackgroundColor,
          button_text_color: COLORS.organisation.defaultProfile.buttonTextColor,
        },
        state: profile.state,
        street_address: profile.street_address,
      });
    }
  }, [profile]);

  async function showProfile() {
    if (orgID === undefined) {
      return;
    }

    const { data: profile } = await profilesAPI.showProfile(
      orgID,
      parseInt(profileID),
    );

    return profile.data;
  }

  if (profile === undefined) {
    return (
      <Layout pageName="Edit Profile">
        <div className="flex items-center justify-center h-full">
          <LoadingAnimation className="w-16 h-16 mx-auto" />
        </div>
      </Layout>
    );
  }

  return (
    <FormProvider {...methods}>
      <Layout pageName="Edit Profile" className="bg-gray-50">
        <EditProfileForm profileGroupHash={profile.group_hash} />
      </Layout>
    </FormProvider>
  );
}

const SettingsSchema = z.object({
  bg_color: z.string(),
  text_color: z.string(),
  button_bg_color: z.string(),
  button_text_color: z.string(),
  company_logo: FileSchema.nullable(),
});

const WorkingProfileFormSchema = z.object({
  city: z.string().nullable(),
  company_name: z.string().nullable(),
  company_phone_number: z.string().nullable(),
  company_website: z.union([z.string().url().nullable(), z.literal('')]),
  contact_exchange_enabled: z.boolean(),
  country: z.string().nullable(),
  custom_design_enabled: z.boolean(),
  email: z.union([z.string().email().nullable(), z.literal('')]),
  extra_emails: z
    .array(z.union([z.string().email(), z.literal('')]))
    .nullable(),
  extra_mobile_numbers: z
    .array(z.union([z.string(), z.literal('')]))
    .nullable(),
  extra_company_phone_numbers: z
    .array(z.union([z.string(), z.literal('')]))
    .nullable(),
  first_name: z.string().nullable(),
  job_title: z.string().nullable(),
  last_name: z.string().nullable(),
  middle_name: z.string().nullable(),
  mobile_number: z.string().nullable(),
  note: z.string().nullable(),
  photo: FileSchema.nullable(),
  post_code: z.string().nullable(),
  profile_file_links: z.array(ProfileFileLinkUnionSchema),
  profile_quick_links: z.array(ProfileQuickLinkSchema),
  profile_social_links: z.array(ProfileSocialLinkSchema),
  pronouns: z.string().nullable(),
  settings: SettingsSchema,
  state: z.string().nullable(),
  street_address: z.string().nullable(),
});

export type WorkingProfileForm = z.infer<typeof WorkingProfileFormSchema>;
