import { ReactNode } from 'react';

import { classNames } from '@/helpers/strings';

type InfoPanelFooterProps = {
  children: ReactNode;
  className?: string;
  wrapClassName?: string;
  childrenClassName?: string;
  fullWidth?: boolean;
};

export default function InfoPanelFooter({
  children,
  className = '',
  wrapClassName = 'fixed bottom-0 left-0 w-full lg:w-[calc(100%-235px)] bg-white border-t border-gray-300 shadow-sm transition transition-bottom z-10',
  childrenClassName = 'px-4 py-3.5 w-full lg:w-auto',
  fullWidth = false,
}: InfoPanelFooterProps) {
  return (
    <div
      className={classNames(
        wrapClassName,
        fullWidth ? '' : 'border-l lg:left-[235px]',
        className,
      )}
    >
      <div
        className={classNames(
          childrenClassName,
          fullWidth ? 'max-w-7xl mx-auto' : '',
        )}
      >
        {children}
      </div>
    </div>
  );
}
