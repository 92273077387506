import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';

import Input from '@/components/Input';
import { IProfileQuickFileLink } from '@/types/IProfile';

interface IEditQuickLink {
  setProfileQuickLinks: React.Dispatch<
    React.SetStateAction<IProfileQuickFileLink[]>
  >;
  profileQuickLinks: IProfileQuickFileLink[];
  quickLink: IProfileQuickFileLink;
  index: number;
  disabled?: boolean;
  shared?: boolean;
}

const swapOrder = (
  result: IProfileQuickFileLink[],
  index: number,
  order: number,
) => {
  const newArray = result.map((item: IProfileQuickFileLink) => {
    if (item.order === order - 1) {
      return {
        ...item,
        order,
      };
    }
    if (item.order === order) {
      return {
        ...item,
        order: order - 1,
      };
    }
    return item;
  });
  const temp = newArray[index];
  newArray[index] = newArray[index - 1];
  newArray[index - 1] = temp;
  return newArray;
};

export default function EditQuickLink({
  setProfileQuickLinks,
  profileQuickLinks,
  quickLink,
  index,
  disabled,
  shared,
}: IEditQuickLink) {
  return (
    <li
      key={`ProfileQuickLink-${index}`}
      className="bg-white border border-gray-300 overflow-hidden sm:rounded-md"
    >
      <div className="flex flex-col sm:flex-row">
        <div className="bg-gray-50 p-3 flex flex-row sm:flex-col justify-end space-x-2 sm:space-x-0 sm:justify-between order-2 sm:order-1">
          <button
            type="button"
            disabled={!!disabled || index === 0}
            onClick={() =>
              setProfileQuickLinks(result =>
                swapOrder(result, index, quickLink.order),
              )
            }
            className="bg-white border border-gray-300 rounded-md text-gray-500 transition hover:bg-gray-50 relative inline-flex items-center p-2 text-sm font-medium disabled:opacity-40"
          >
            <ArrowUpIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            disabled={!!disabled || index === profileQuickLinks.length - 1}
            onClick={() =>
              setProfileQuickLinks(result =>
                swapOrder(result, index + 1, quickLink.order + 1),
              )
            }
            className="bg-white border border-gray-300 rounded-md text-gray-500 transition hover:bg-gray-50 relative inline-flex items-center p-2 text-sm font-medium disabled:opacity-40"
          >
            <ArrowDownIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="flex-grow order-1 sm:order-2">
          <div className="py-4 px-4 sm:px-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <Input
                  id={`"profileQuickLinkTitle-${index}`}
                  label="Link button text"
                  type="text"
                  value={profileQuickLinks[index].title}
                  onChange={title => {
                    setProfileQuickLinks((result: IProfileQuickFileLink[]) =>
                      result.map((item: IProfileQuickFileLink) => {
                        if (item.order === quickLink.order) {
                          return {
                            ...item,
                            title,
                          };
                        }
                        return item;
                      }),
                    );
                  }}
                  message="This will appear as a button on your profile"
                  disabled={disabled}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <Input
                  id={`"profileQuickLinkValue-${index}`}
                  label="Link address"
                  type="text"
                  value={profileQuickLinks[index].value || ''}
                  onChange={value => {
                    setProfileQuickLinks((result: IProfileQuickFileLink[]) =>
                      result.map((item: IProfileQuickFileLink) => {
                        if (item.order === quickLink.order) {
                          return {
                            ...item,
                            value,
                          };
                        }
                        return item;
                      }),
                    );
                  }}
                  message="Clicking the button will go here"
                  disabled={disabled}
                />
              </div>
            </div>
            {!disabled && (
              <div className="pt-4">
                <button
                  type="button"
                  onClick={() => {
                    if (shared) {
                      setProfileQuickLinks((result: IProfileQuickFileLink[]) =>
                        result.map((item: IProfileQuickFileLink) => {
                          if (item.order === quickLink.order) {
                            return {
                              ...item,
                              order: -1,
                            };
                          }
                          return item;
                        }),
                      );
                    } else {
                      setProfileQuickLinks((result: IProfileQuickFileLink[]) =>
                        result.filter(
                          (item: IProfileQuickFileLink) =>
                            item.order !== quickLink.order,
                        ),
                      );
                    }
                  }}
                  className="appearance-none text-brand-500 text-sm font-medium"
                  disabled={disabled}
                >
                  Remove link
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}
