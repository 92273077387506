import { Fragment, useCallback, useEffect, useState } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import accountsAPI from '@/api/accounts';
import adminAPI from '@/api/admin';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import Input from '@/components/Input';
import InputSelect from '@/components/InputSelect';
import Layout from '@/components/Layout';
import Modal from '@/components/Modals/Modal';
import Search from '@/components/Search';
import MESSAGES from '@/constants/messages-en';
import { validateEmail } from '@/helpers/validate';
import IOrganisation from '@/types/IOrganisation';
import IUser from '@/types/IUser';

export default function AccountSettingsAdminPage() {
  const [inviteUserIsOpen, setInviteUserIsOpen] = useState<boolean>(false);
  const [inviteUserEmail, setInviteUserEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [accountSuccess, setAccountSuccess] = useState<string>('');
  const [accountLoading, setAccountLoading] = useState<boolean>(false);
  const [accountError, setAccountError] = useState<boolean>(false);
  const [saleSuccess, setSaleSuccess] = useState<string>('');
  const [saleError, setSaleError] = useState<boolean>(false);
  const [designSuccess, setDesignSuccess] = useState<string>('');
  const [designError, setDesignError] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [admins, setAdmins] = useState<IUser[]>([]);
  const [salesAccounts, setSalesAccounts] = useState<IUser[]>([]);
  const [designAccounts, setDesignAccounts] = useState<IUser[]>([]);

  const [deleteAccountIsOpen, setDeleteAccountIsOpen] =
    useState<boolean>(false);
  const [resetPasswordIsOpen, setResetPasswordIsOpen] =
    useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<IUser>();
  const [orgMoveIsOpen, setOrgMoveIsOpen] = useState<boolean>(false);
  const [orgSearch, setOrgSearch] = useState<string>('');
  const [moveOrgID, setMoveOrgID] = useState<number>(-1);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [orgData, setOrgData] = useState<IOrganisation[]>();
  const [inviteUserRole, setInviteUserRole] = useState<string>('admin');

  const ROLES = {
    admin: 'admin',
    sales: 'sale',
    design: 'design',
  };

  const listOrganisations = useCallback(async (newOrgSearch: string) => {
    const res = await adminAPI.listOrganisations({
      pageSize: 5,
      search: newOrgSearch,
    });

    setOrgData(res.data?.data?.filter((item: IOrganisation) => item.name));

    return res.data;
  }, []);

  const showSuccess = (message: string) => {
    switch (inviteUserRole) {
      case ROLES.sales:
        setSaleSuccess(message);
        break;
      case ROLES.design:
        setDesignSuccess(message);
        break;
      default:
        setAccountSuccess(message);
    }
  };

  const showError = (flag: boolean) => {
    switch (inviteUserRole) {
      case ROLES.sales:
        setSaleError(flag);
        break;
      case ROLES.design:
        setDesignError(flag);
        break;
      default:
        setAccountError(flag);
    }
  };

  const handleInviteUser = () => {
    showSuccess('');
    showError(false);
    setEmailError(false);

    if (validateEmail(inviteUserEmail)) {
      setEmailError(false);
      adminAPI
        .inviteUser({
          invite: {
            email: inviteUserEmail,
            role: inviteUserRole,
          },
        })
        .then(() => showSuccess('The user was invited successfully'))
        .catch(() => showError(true))
        .then(() => setAccountLoading(false))
        .finally(() => setInviteUserIsOpen(false));
    } else {
      setEmailError(true);
      setAccountLoading(false);
    }
  };

  const getUsers = useCallback(() => {
    setAccountLoading(true);
    adminAPI
      .listUsers(1, 50, { scope: 'admin' })
      .then(res => {
        setAdmins(res.data?.data?.filter((item: IUser) => item.role === null));
        setSalesAccounts(
          res.data?.data?.filter((item: IUser) => item.role === ROLES.sales),
        );
        setDesignAccounts(
          res.data?.data?.filter((item: IUser) => item.role === ROLES.design),
        );
      })
      .finally(() => setAccountLoading(false));
  }, []);

  const handleDeleteAccount = (userID: number) => {
    setAccountSuccess('');
    setAccountError(false);
    setAccountLoading(true);
    adminAPI
      .deleteUser(userID)
      .then(() => setAccountSuccess(MESSAGES.account.delete.success))
      .catch(() => setAccountError(true))
      .then(() => setAccountLoading(false))
      .then(() => setDeleteAccountIsOpen(false))
      .finally(() => getUsers());
  };

  const handleResetPassword = (email: string) => {
    setAccountSuccess('');
    setAccountError(false);
    setAccountLoading(true);
    accountsAPI
      .forgotPassword({ forgot: { email } })
      .then(() => setAccountSuccess(MESSAGES.account.reset.success))
      .catch(() => setAccountError(true))
      .then(() => setAccountLoading(false))
      .finally(() => setResetPasswordIsOpen(false));
  };

  const handleMoveAccount = (roleID: number, organisation_id: number) => {
    setAccountSuccess('');
    setAccountLoading(true);
    adminAPI
      .updateOrganisationRole(roleID, {
        organisation_role: {
          organisation_id,
        },
      })
      .then(() => setAccountSuccess(MESSAGES.account.organisation.success))
      .catch(() => setAccountError(true))
      .then(() => setAccountLoading(false))
      .finally(() => setOrgMoveIsOpen(false));
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <Layout pageName="Settings">
      <Modal
        isOpen={inviteUserIsOpen}
        setIsOpen={setInviteUserIsOpen}
        dialogTitle="Invite user"
        successButtonText="Send email invitation"
        onSuccess={() => handleInviteUser()}
        isLoading={accountLoading}
      >
        <div className="mt-6">
          {emailError && <ErrorAlert message={MESSAGES.error.email} />}
          <Input
            label="Email"
            value={inviteUserEmail}
            onChange={setInviteUserEmail}
            type="text"
          />
        </div>

        <div className="mt-4">
          <InputSelect
            id="role"
            label="Role"
            value={inviteUserRole}
            onChange={setInviteUserRole}
            options={[
              {
                label: 'Super Admin',
                value: ROLES.admin,
              },
              {
                label: 'Sales',
                value: ROLES.sales,
              },
              {
                label: 'Design',
                value: ROLES.design,
              },
            ]}
          />
        </div>
      </Modal>
      <Modal
        isOpen={deleteAccountIsOpen}
        setIsOpen={setDeleteAccountIsOpen}
        dialogTitle={MESSAGES.account.delete.heading}
        dialogDescription={MESSAGES.account.delete.description(
          selectedAccount?.email || '',
        )}
        successButtonText="Delete account"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={() => {
          if (selectedAccount) handleDeleteAccount(selectedAccount?.id);
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isLoading={accountLoading}
      />
      <Modal
        isOpen={resetPasswordIsOpen}
        setIsOpen={setResetPasswordIsOpen}
        dialogTitle={MESSAGES.account.reset.heading}
        dialogDescription={MESSAGES.account.reset.description}
        successButtonText="Reset password"
        onSuccess={() => {
          if (selectedAccount) handleResetPassword(selectedAccount?.email);
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isLoading={accountLoading}
      />
      <Modal
        isOpen={orgMoveIsOpen}
        setIsOpen={setOrgMoveIsOpen}
        dialogTitle={MESSAGES.account.organisation.heading}
        dialogDescription={MESSAGES.account.organisation.description}
        successButtonText="Move account"
        onSuccess={() => {
          if (selectedAccount) {
            handleMoveAccount(selectedAccount?.id, moveOrgID);
          }
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isDisabled={moveOrgID === -1}
        isLoading={accountLoading}
      >
        <div className="pt-4 relative">
          <Search
            id={`OrganisationList-1-20-date-desc--${orgSearch}`}
            placeholder="Search for organisations"
            search={orgSearch}
            setSearch={value => {
              if (value !== orgSearch) {
                setMoveOrgID(-1);
                setShowOptions(true);
              }
              setOrgSearch(value);
            }}
            fetchQuery={newOrgSearch => listOrganisations(newOrgSearch)}
          />
          {orgSearch && showOptions && (
            <div className="origin-top-left absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              <div className="py-1">
                {orgData && orgData?.length ? (
                  orgData?.map((item: IOrganisation) => (
                    <button
                      type="button"
                      key={item.id}
                      className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left"
                      onClick={() => {
                        setMoveOrgID(item.id);
                        setShowOptions(false);
                        setOrgSearch(`#${item.id} ${item.name}`);
                      }}
                    >
                      #{item.id}{' '}
                      <span className="text-gray-500">{item.name}</span>
                    </button>
                  ))
                ) : (
                  <li className="px-3 py-2 text-center">
                    No matching items found
                  </li>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
      <div className="mt-10">
        <InfoPanelContainer title="Super Admin Accounts">
          {accountSuccess !== '' && <SuccessAlert message={accountSuccess} />}
          {accountError && <ErrorAlert message={MESSAGES.error.generic} />}
          <div className="mb-6">
            <ul className="divide-y divide-gray-200">
              {admins &&
                admins.map(item => (
                  <li key={item.id}>
                    <div className="block bg-white">
                      <div className="flex items-center justify-between py-4">
                        <div>
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {item.first_name} {item.last_name}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            {item.email}
                          </p>
                        </div>
                        <div className="inline-flex space-x-6 items-center">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                                Manage user
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                <div className="py-1">
                                  <Menu.Item>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setDeleteAccountIsOpen(true);
                                        setSelectedAccount(item);
                                      }}
                                      className="appearance-none text-red-500 w-full text-left block px-4 py-2 text-sm"
                                    >
                                      Delete account
                                    </button>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setResetPasswordIsOpen(true);
                                        setSelectedAccount(item);
                                      }}
                                      className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                    >
                                      Reset password
                                    </button>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setOrgMoveIsOpen(true);
                                        setSelectedAccount(item);
                                      }}
                                      className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                    >
                                      Assign to another organisation
                                    </button>
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Invite user"
            onClick={() => setInviteUserIsOpen(true)}
          />
        </InfoPanelContainer>
        <ul className="divide-y divide-gray-200" />
        <InfoPanelDivider />
        <InfoPanelContainer title="Sales Accounts">
          {saleSuccess !== '' && <SuccessAlert message={saleSuccess} />}
          {saleError && <ErrorAlert message={MESSAGES.error.generic} />}
          <div className="mb-6">
            <ul className="divide-y divide-gray-200">
              {salesAccounts &&
                salesAccounts.map(item => (
                  <li key={item.id}>
                    <div className="block bg-white">
                      <div className="flex items-center justify-between py-4">
                        <div>
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {item.first_name} {item.last_name}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            {item.email}
                          </p>
                        </div>
                        <div className="inline-flex space-x-6 items-center">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                                Manage user
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                <div className="py-1">
                                  <Menu.Item>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setDeleteAccountIsOpen(true);
                                        setSelectedAccount(item);
                                      }}
                                      className="appearance-none text-red-500 w-full text-left block px-4 py-2 text-sm"
                                    >
                                      Delete account
                                    </button>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setResetPasswordIsOpen(true);
                                        setSelectedAccount(item);
                                      }}
                                      className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                    >
                                      Reset password
                                    </button>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setOrgMoveIsOpen(true);
                                        setSelectedAccount(item);
                                      }}
                                      className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                    >
                                      Assign to another organisation
                                    </button>
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Invite user"
            onClick={() => {
              setInviteUserRole(ROLES.sales);
              setInviteUserIsOpen(true);
            }}
          />
        </InfoPanelContainer>
        <InfoPanelDivider />
        <InfoPanelContainer title="Design Accounts">
          {designSuccess !== '' && <SuccessAlert message={designSuccess} />}
          {designError && <ErrorAlert message={MESSAGES.error.generic} />}
          <div className="mb-6">
            <ul className="divide-y divide-gray-200">
              {designAccounts &&
                designAccounts.map(item => (
                  <li key={item.id}>
                    <div className="block bg-white">
                      <div className="flex items-center justify-between py-4">
                        <div>
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {item.first_name} {item.last_name}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            {item.email}
                          </p>
                        </div>
                        <div className="inline-flex space-x-6 items-center">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                                Manage user
                                <ChevronDownIcon
                                  className="-mr-1 ml-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                <div className="py-1">
                                  <Menu.Item>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setDeleteAccountIsOpen(true);
                                        setSelectedAccount(item);
                                      }}
                                      className="appearance-none text-red-500 w-full text-left block px-4 py-2 text-sm"
                                    >
                                      Delete account
                                    </button>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setResetPasswordIsOpen(true);
                                        setSelectedAccount(item);
                                      }}
                                      className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                    >
                                      Reset password
                                    </button>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setOrgMoveIsOpen(true);
                                        setSelectedAccount(item);
                                      }}
                                      className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                    >
                                      Assign to another organisation
                                    </button>
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Invite user"
            onClick={() => {
              setInviteUserRole(ROLES.design);
              setInviteUserIsOpen(true);
            }}
          />
        </InfoPanelContainer>
      </div>
    </Layout>
  );
}
