import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import { ContactExchange } from '@/components/Templates/ContactExchange';
import {
  DigitalWallet,
  DigitalWalletBanner,
} from '@/components/Templates/DigitalWallet';
import EmailSignature from '@/components/Templates/EmailSignature';
import { ProfileDesign } from '@/components/Templates/ProfileDesign';
import { StateProvider } from '@/hooks/useAppState';
import useAuth, { AuthProvider } from '@/hooks/useAuth';
import { DialogProvider } from '@/hooks/useDialog';
import { ShopifyProvider } from '@/hooks/useShopify';
import AccountRecoveryPage from '@/pages/AccountRecoveryPage';
import ActivationKeysListPage from '@/pages/ActivationKeysListPage';
import AnalyticsAdminPage from '@/pages/AnalyticsAdminPage';
import AnalyticsPage from '@/pages/AnalyticsPage';
import ContactListAdminPage from '@/pages/ContactListAdminPage';
import ContactListPage from '@/pages/ContactListPage';
import CreateGroupPage from '@/pages/CreateGroupPage';
import EditGroupPage from '@/pages/EditGroupPage';
import EditProfileAdminPage from '@/pages/EditProfileAdminPage';
import EditProfilePage from '@/pages/EditProfilePage';
import EditSharedProfilePage from '@/pages/EditSharedProfilePage';
import EmailSignaturePreviewPage from '@/pages/EmailSignaturePreviewPage';
import GroupListPage from '@/pages/GroupListPage';
import LoginPage from '@/pages/LoginPage';
import NewActivationPage from '@/pages/NewActivationPage';
import NotFoundPage from '@/pages/NotFoundPage';
import OldAnalyticsPage from '@/pages/OldAnalyticsPage';
import OrdersV2ListAdminPage from '@/pages/OrderV2Page/OrdersV2ListAdmin/Page';
import OrganisationsListPage from '@/pages/OrganisationsListPage';
import PilotsListPage from '@/pages/PilotsListPage';
import PreViewPage from '@/pages/PreviewPage';
import ProductsListAdminPage from '@/pages/ProductsListAdminPage';
import ProductsListPage from '@/pages/ProductsListPage';
import ProfileGeneratorPage from '@/pages/ProfileGeneratorPage';
import ProfileListAdminPage from '@/pages/ProfileListAdminPage';
import ProfileListGroupPage from '@/pages/ProfileListGroupPage';
import ProfileListPage from '@/pages/ProfileListPage';
import ResetPasswordPage from '@/pages/ResetPasswordPage';
import SampleProfilePage from '@/pages/SampleProfilePage';
import AccountSettingsAdminPage from '@/pages/SettingsAdminPage';
import AccountSettingsPage from '@/pages/SettingsPage';
import ShopPage from '@/pages/ShopPage';
import ShopifyLoginPage from '@/pages/ShopifyLoginPage';
import ShopifyVerifyOTPCodePage from '@/pages/ShopifyVerifyOTPCodePage';
import SignUpPage from '@/pages/SignUpPage';
import SsoConsumePage from '@/pages/SsoConsumePage';
import SuperuserAnalyticsPage from '@/pages/SuperuserAnalyticsPage';
import TestComponents from '@/pages/TestComponents';
import VerifyOTPCodePage from '@/pages/VerifyOTPCodePage';
import ViewOrganisationAdminPage from '@/pages/ViewOrganisationAdminPage';
import ViewOrganisationPage from '@/pages/ViewOrganisationPage';
import ViewProfilePage from '@/pages/ViewProfilePage';
import SubscriptionStatus from '@/types/SubscriptionStatus';

import Layout from './Layout';
import { PseudoTabs, TemplatesPseudoTabs } from './PseudoTabs';

const queryClient = new QueryClient();

function AuthenticatedRoutes() {
  const { user } = useAuth();
  const location = useLocation();

  if (user === undefined) {
    return <Navigate to="/login" />;
  }

  if (
    location.pathname !== '/settings' &&
    user.platform_subscription_status === SubscriptionStatus.INACTIVE
  ) {
    return <Navigate to="/settings" replace />;
  }

  return <Outlet />;
}

function RootLayout() {
  const location = useLocation();

  useEffect(() => {
    const gaMeasurementId =
      (import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID as string) ||
      'G-12345';
    ReactGA.initialize(gaMeasurementId);
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${location.pathname}${location.search}`,
    });
  }, [location]);

  return (
    <IntercomProvider
      appId={import.meta.env.VITE_INTERCOM_WORKSPACE_ID}
      autoBootProps={{
        hideDefaultLauncher: true,
      }}
      autoBoot
    >
      <AuthProvider>
        <StateProvider>
          <QueryClientProvider client={queryClient}>
            <ShopifyProvider>
              <DialogProvider>
                <Outlet />
              </DialogProvider>
            </ShopifyProvider>
          </QueryClientProvider>
        </StateProvider>
      </AuthProvider>
    </IntercomProvider>
  );
}

export default function Router() {
  return <RouterProvider router={router} />;
}

const router = createBrowserRouter([
  {
    id: 'root',
    element: <RootLayout />,
    children: [
      // public routes
      { path: '/login', element: <LoginPage /> },
      { path: '/sign-up', element: <SignUpPage /> },
      { path: '/verify-mfa', element: <VerifyOTPCodePage /> },
      { path: '/reset-password', element: <ResetPasswordPage /> },
      { path: '/account-recovery', element: <AccountRecoveryPage /> },
      { path: '/sso', element: <SsoConsumePage /> },
      { path: '/view-card/:card_hash_id', element: <NewActivationPage /> },
      { path: '/shopify/verify-mfa', element: <ShopifyVerifyOTPCodePage /> },
      { path: '/shopify/login', element: <ShopifyLoginPage /> },
      { path: '/preview/:order_uuid', element: <PreViewPage /> },
      {
        path: '/email-signature-preview/:profile_hash_id',
        element: <EmailSignaturePreviewPage />,
      },
      // protected routes
      {
        element: <AuthenticatedRoutes />,
        children: [
          { path: '/', element: <AuthenticatedRootElement /> },
          {
            path: '/view-profile/:profile_hash_id',
            element: <ViewProfilePage />,
          },
          {
            path: '/create-group',
            element: <CreateGroupPage />,
          },
          {
            path: '/groups',
            element: <GroupListPage />,
          },
          { path: '/view-profile-sample', element: <SampleProfilePage /> },
          {
            path: '/manage-account/:account_id/:first_name/:last_name/:email',
            element: <AccountSettingsPage />,
          },
          // conditional/superuser routes
          { path: '/settings', element: <AccountSettings /> },
          { path: '/analytics', element: <Analytics /> },
          { path: '/analytics2', element: <Analytics2 /> },
          { path: '/organisations', element: <OrganisationList /> },
          { path: '/activation-keys', element: <ActivationKeysList /> },
          { path: '/profile-generator', element: <ProfileGenerator /> },
          { path: '/pilots', element: <Pilots /> },
          {
            path: '/view-organisation/:organisation_id',
            element: <ViewOrganisation />,
          },
          {
            path: '/edit-profile/:profile_id',
            element: <EditProfile />,
          },
          { path: '/contacts', element: <ContactsList /> },
          { path: '/profiles-list', element: <ProfilesListAdmin /> },
          { path: '/orders-v2/:orderDetailUUID?', element: <OrdersV2 /> },
          // other
          { path: '/edit-shared-profile', element: <EditSharedProfilePage /> },
          { path: '/edit-group/:id', element: <EditGroupPage /> },
          { path: '/products', element: <ProductsListPage /> },
          {
            path: '/templates',
            element: <TemplatesLayout />,
            children: [
              {
                index: true,
                element: <Navigate to="/templates/profile-design" replace />,
              },
              {
                path: 'profile-design',
                element: <ProfileDesign />,
              },
              {
                path: 'contact-exchange',
                element: <ContactExchange />,
              },
              {
                path: 'email-signature',
                element: <EmailSignature />,
              },
              {
                path: 'digital-wallet',
                element: <DigitalWallet />,
              },
            ],
          },
          { path: '/shop', element: <ShopPage /> },
        ],
      },
    ],
  },
  { path: '/test', element: <TestComponents /> },
  { path: '*', element: <NotFoundPage /> },
]);

const isSuperUser = (userScope: string | undefined) => {
  return userScope === 'admin';
};
const isSuperUserAdmin = (
  userScope: string | undefined,
  adminRole: string | null | undefined,
) => {
  return isSuperUser(userScope) && adminRole === null;
};
const isSalesAdmin = (
  userScope: string | undefined,
  adminRole: string | null | undefined,
) => {
  return (
    isSuperUserAdmin(userScope, adminRole) ||
    (userScope === 'admin' && adminRole === 'sale')
  );
};
const isDesignAdmin = (
  userScope: string | undefined,
  adminRole: string | null | undefined,
) => {
  return (
    isSuperUserAdmin(userScope, adminRole) ||
    (userScope === 'admin' && adminRole === 'design')
  );
};
const isOrgAdmin = (
  userScope: string | undefined,
  userRole: string | undefined,
) => {
  return userScope === 'user' && userRole === 'org_admin';
};

function OrdersV2() {
  const { userScope } = useAuth();

  return isSuperUser(userScope) ? <OrdersV2ListAdminPage /> : <NotFoundPage />;
}

function AuthenticatedRootElement() {
  const { userScope, userRole } = useAuth();

  if (isSuperUser(userScope)) {
    return <ProductsListAdminPage />;
  }

  if (isOrgAdmin(userScope, userRole)) {
    return <ProfileListPage />;
  }

  return <ProfileListGroupPage />;
}

function ProfilesListAdmin() {
  const { userScope } = useAuth();

  return isSuperUser(userScope) ? <ProfileListAdminPage /> : <NotFoundPage />;
}

function AccountSettings() {
  const { adminRole, userRole, userScope } = useAuth();

  if (isSuperUserAdmin(userScope, adminRole)) {
    return <AccountSettingsAdminPage />;
  }

  if (isOrgAdmin(userScope, userRole)) {
    return <AccountSettingsPage />;
  }

  return <NotFoundPage />;
}

function Analytics() {
  const { userScope } = useAuth();

  return isSuperUser(userScope) ? <AnalyticsAdminPage /> : <OldAnalyticsPage />;
}

function Analytics2() {
  const { userScope } = useAuth();

  return isSuperUser(userScope) ? (
    <SuperuserAnalyticsPage />
  ) : (
    <AnalyticsPage />
  );
}

function OrganisationList() {
  const { userScope } = useAuth();

  return isSuperUser(userScope) ? <OrganisationsListPage /> : <NotFoundPage />;
}

function ActivationKeysList() {
  const { adminRole, userScope } = useAuth();

  return isDesignAdmin(userScope, adminRole) ? (
    <ActivationKeysListPage />
  ) : (
    <NotFoundPage />
  );
}

function ProfileGenerator() {
  const { userScope } = useAuth();

  return isSuperUser(userScope) ? <ProfileGeneratorPage /> : <NotFoundPage />;
}

function Pilots() {
  const { adminRole, userScope } = useAuth();

  return isSalesAdmin(userScope, adminRole) ? (
    <PilotsListPage />
  ) : (
    <NotFoundPage />
  );
}

function ViewOrganisation() {
  const { userScope } = useAuth();

  return isSuperUser(userScope) ? (
    <ViewOrganisationAdminPage />
  ) : (
    <ViewOrganisationPage />
  );
}

function EditProfile() {
  const { userScope } = useAuth();

  return isSuperUser(userScope) ? (
    <EditProfileAdminPage />
  ) : (
    <EditProfilePage />
  );
}

function ContactsList() {
  const { userScope } = useAuth();

  return isSuperUser(userScope) ? (
    <ContactListAdminPage />
  ) : (
    <ContactListPage />
  );
}

function TemplatesLayout() {
  const location = useLocation();
  const [, , currentTab] = location.pathname.split('/');

  const isDigitalWalletTab = currentTab === 'digital-wallet';

  return (
    <Layout
      pageName="Templates"
      className="bg-gray-50"
      rightTitle={isDigitalWalletTab && <DigitalWalletBanner />}
    >
      <PseudoTabs
        tabs={TemplatesPseudoTabs}
        activeTab={currentTab || 'profile-design'}
      />
      <Outlet />
    </Layout>
  );
}
