import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import Resizer from 'react-image-file-resizer';

import { Slider } from '@mui/material';

import Button, { BUTTON_KIND } from '@/components/Button';

interface ImageCropperProps {
  aspectRatio?: number;
  filePath: string;
  loading: boolean;
  onClose(): void;
  onCropSubmit(file: Blob): void;
  maxHeight: number;
  maxWidth: number;
}

function dataURLtoBlob(dataurl: string) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataurl.split(',')[1]);

  // separate out the mime component
  const mimeString = dataurl.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
}

const ImageCropper = ({
  aspectRatio = 1,
  filePath,
  loading,
  onClose,
  onCropSubmit,
  maxHeight,
  maxWidth,
}: ImageCropperProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const onCropComplete = useCallback(
    (
      _: unknown,
      newCroppedAreaPixels: {
        x: number;
        y: number;
        width: number;
        height: number;
      },
    ) => {
      setCroppedAreaPixels(newCroppedAreaPixels);
    },
    [],
  );

  const onCropButtonClicked = useCallback(async () => {
    const resizeCanvas = document.createElement('canvas');
    const image = new Image();
    image.src = filePath;

    image.onerror = error => {
      console.error('Image failed to load:', error);
      // Handle the error appropriately
    };

    image.onload = () => {
      if (
        image &&
        filePath &&
        resizeCanvas &&
        resizeCanvas.getContext('2d') &&
        croppedAreaPixels
      ) {
        resizeCanvas.width = croppedAreaPixels.width;
        resizeCanvas.height = croppedAreaPixels.height;

        resizeCanvas
          .getContext('2d')
          ?.drawImage(
            image,
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
          );

        const blob = dataURLtoBlob(resizeCanvas.toDataURL('image/png'));
        Resizer.imageFileResizer(
          blob,
          maxWidth,
          maxHeight,
          'JPEG',
          100,
          0,
          blob => {
            if (blob instanceof Blob) {
              onCropSubmit(blob);
            }
          },
          'blob',
        );
      }
    };
  }, [filePath, croppedAreaPixels, onCropSubmit]);

  const onZoomChange = useCallback((_: unknown, newZoom: number | number[]) => {
    const res = newZoom as number;
    setZoom(res);
  }, []);

  return (
    <div className="flex flex-col items-center">
      <div className="relative w-80 h-80">
        <Cropper
          image={filePath}
          crop={crop}
          zoom={zoom}
          minZoom={0.2}
          aspect={aspectRatio}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          restrictPosition={false}
        />
      </div>
      <div className="w-full mt-6">
        <Slider
          value={zoom}
          min={0.2}
          max={2}
          step={0.01}
          aria-labelledby="Zoom"
          onChange={onZoomChange}
        />
      </div>
      <div className="flex flex-row items-center justify-end w-full">
        <Button
          buttonText="Cancel"
          kind={BUTTON_KIND.WHITE}
          onClick={onClose}
        />
        <div className="ml-2" />
        <Button
          loading={loading}
          buttonText="Set photo"
          kind={BUTTON_KIND.PRIMARY}
          onClick={onCropButtonClicked}
        />
      </div>
    </div>
  );
};

export default ImageCropper;
