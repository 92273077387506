import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import LayoutStandard from '@/components/LayoutStandard';
import { searchParamsQuery } from '@/helpers/urlHandler';
import useAuth from '@/hooks/useAuth';
import Cookies from 'js-cookie';

export default function SsoConsumePage() {
  const { ssoLogin, user } = useAuth();
  const location = useLocation();
  const { access_token: accessToken, csrf_token: csrfToken } = searchParamsQuery(location.search);

  const validateTokens = () => {
    // Validate URL tokens
    if (!csrfToken || !accessToken) {
      return false;
    }

    // Validate stored CSRF token
    try {
      const storedTokenCookie = Cookies.get('sso_csrf_token');
      if (!storedTokenCookie) {
        return false;
      }
      const storedToken = atob(storedTokenCookie).split(':')[1];
      const receivedToken = atob(csrfToken).split(':')[1];

      if (storedToken === receivedToken) {
        Cookies.set('sso_csrf_token', '', { expires: -1 });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (accessToken && csrfToken) {
      ssoLogin(accessToken);
    }
  }, [accessToken, csrfToken, ssoLogin]);

  // Redirect cases
  if (!validateTokens()) {
    return <Navigate to="/login" replace />;
  }

  if (user) {
    return <Navigate to="/"/>;
  }

  // Loading state
  return (
    <LayoutStandard heading="Log in to your Tapt account">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md text-center">
        Logging in...
      </div>
    </LayoutStandard>
  );
}
