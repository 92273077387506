import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import ColorPicker from '@/components/ColorPicker';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import ToggleField from '@/components/ToggleField';
import UploadPhoto from '@/components/UploadPhoto';
import { WorkingProfileForm } from '@/pages/EditProfilePage';
import IFile from '@/types/IFile';

export function ProfilePageAppearance() {
  const { watch, setValue } = useFormContext<WorkingProfileForm>();

  const isCustomDesignEnabled = watch('custom_design_enabled');

  const [companyLogo, setCompanyLogo] = useState<IFile | undefined>(undefined);

  useEffect(() => {
    if (companyLogo === undefined) {
      setValue('settings.company_logo', null);
    } else {
      setValue('settings.company_logo', companyLogo);
    }
  }, [companyLogo]);

  const colorPickers = [
    {
      title: 'Background colour',
      value: watch('settings.bg_color'),
      setter: (color: string) => setValue('settings.bg_color', color),
    },
    {
      title: 'Text colour',
      value: watch('settings.text_color'),
      setter: (color: string) => setValue('settings.text_color', color),
    },
    {
      title: 'Button colour',
      value: watch('settings.button_bg_color'),
      setter: (color: string) => setValue('settings.button_bg_color', color),
    },
    {
      title: 'Button text colour',
      value: watch('settings.button_text_color'),
      setter: (color: string) => setValue('settings.button_text_color', color),
    },
  ];

  return (
    <InfoPanelContainer
      title="Profile page appearance"
      information="The default template is organisation-wide and is applied to any profile not assigned a custom profile page appearance."
    >
      <div className="space-y-6">
        <ToggleField
          label="Assign a custom profile page design to this profile holder"
          enabled={isCustomDesignEnabled || false}
          setter={enabled => setValue('custom_design_enabled', enabled)}
        />
        {isCustomDesignEnabled && (
          <>
            <UploadPhoto
              title="Cover image"
              photo={companyLogo}
              setPhoto={setCompanyLogo}
              aspectRatio={320 / 123}
              maxHeight={320}
              maxWidth={123}
              fileFormatMessage="Recommended dimensions 320px x 123px"
              size="large"
            />
            <div className="flex items-center gap-6 flex-wrap">
              {colorPickers.map((picker, index) => (
                <div key={index} className="space-y-3">
                  <span className="text-sm font-medium text-gray-900">
                    {picker.title}
                  </span>
                  <ColorPicker color={picker.value} setColor={picker.setter} />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </InfoPanelContainer>
  );
}
