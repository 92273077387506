import { useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';

import organisationsAPI from '@/api/organisations';
import SampleQRCode from '@/assets/images/email_signature_qr.png';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import ColorPicker from '@/components/ColorPicker';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import EmailSignaturePreview from '@/components/Templates/EmailSignature/Preview';
import ToggleField from '@/components/ToggleField';
import { UnsavedChangesModal } from '@/components/UnsavedChangesPrompt';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';
import IFile from '@/types/IFile';
import { IEmailSignature, IEmailSignatureAssets } from '@/types/IOrganisation';
import { IProfileSignature } from '@/types/IProfile';

import Modal from '../Modals/Modal';
import UploadPhoto from '../UploadPhoto';

export default function EmailSignature() {
  const { orgID } = useAuth();

  // Personal information toggles
  const [fullNameToggle, setFullNameToggle] = useState(true);
  const [profileImageToggle, setProfileImageToggle] = useState(true);
  const [jobTitleToggle, setJobTitleToggle] = useState(true);
  const [mobileNumberToggle, setMobileNumberToggle] = useState(true);
  const [emailToggle, setEmailToggle] = useState(true);
  const [socialMediaToggle, setSocialMediaToggle] = useState(true);

  // Company information toggles
  const [companyNameToggle, setCompanyNameToggle] = useState(true);
  const [companySiteToggle, setCompanySiteToggle] = useState(true);
  const [companyPhoneToggle, setCompanyPhoneToggle] = useState(true);
  const [companyAddressToggle, setCompanyAddressToggle] = useState(true);
  const [companyLogo, setCompanyLogo] = useState<IFile | undefined>(undefined);
  const [bannerImage, setBannerImage] = useState<IFile | undefined>(undefined);

  // Colors
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
  const [textColor, setTextColor] = useState('#000000');
  const [primaryTextColor, setPrimaryTextColor] = useState('#2B2B2B');
  const [showQRCode, setShowQRCode] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [disclaimer, setDisclaimer] = useState('');

  const [isDirty, setIsDirty] = useState(false);
  const [organisationError, setOrganisationError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined,
  );

  const blocker = useBlocker(isDirty);

  useEffect(() => {
    getEmailSignature();
  }, [orgID]);

  const makeFormDirty = () => {
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  async function getEmailSignature() {
    if (orgID === undefined) {
      return;
    }

    try {
      const {
        data: { data: emailSignature },
      } = await organisationsAPI.getEmailSignature(orgID);
      setFullNameToggle(emailSignature.show_full_name);
      setProfileImageToggle(emailSignature.show_profile_image);
      setJobTitleToggle(emailSignature.show_job_title);
      setMobileNumberToggle(emailSignature.show_mobile_number);
      setEmailToggle(emailSignature.show_email);
      setSocialMediaToggle(emailSignature.show_social_links);
      setCompanyNameToggle(emailSignature.show_company_name);
      setCompanySiteToggle(emailSignature.show_company_website);
      setCompanyPhoneToggle(emailSignature.show_company_phone);
      setCompanyAddressToggle(emailSignature.show_company_address);
      setBackgroundColor(emailSignature.background_color);
      setTextColor(emailSignature.text_color);
      setPrimaryTextColor(emailSignature.primary_text_color || '#2B2B2B');
      setShowQRCode(emailSignature.show_qr_code);
      setShowBanner(emailSignature.show_banner);
      if (emailSignature.disclaimer_text) {
        setDisclaimer(emailSignature.disclaimer_text);
      }
      setBannerImage(emailSignature.banner_file);
      setCompanyLogo(emailSignature.logo_file);
    } catch {
      setOrganisationError(true);
    }
  }

  async function handleEmailSignatureSave() {
    if (orgID === undefined) {
      return;
    }

    try {
      setIsSaving(true);
      setSuccessMessage(undefined);
      setOrganisationError(false);

      await organisationsAPI
        .updateEmailSignature(orgID, {
          email_signature: {
            show_full_name: fullNameToggle,
            show_profile_image: profileImageToggle,
            show_job_title: jobTitleToggle,
            show_mobile_number: mobileNumberToggle,
            show_email: emailToggle,
            show_social_links: socialMediaToggle,
            show_company_name: companyNameToggle,
            show_company_website: companySiteToggle,
            show_company_phone: companyPhoneToggle,
            show_company_address: companyAddressToggle,
            background_color: backgroundColor,
            text_color: textColor,
            primary_text_color: primaryTextColor,
            show_qr_code: showQRCode,
            show_banner: showBanner,
            disclaimer_text: disclaimer,
            banner_file_id: bannerImage?.id || null,
            logo_file_id: companyLogo?.id || null,
          },
        })
        .then(() => {
          setIsDirty(false);
        });

      setSuccessMessage(MESSAGES.organisation.emailSignature);
    } catch {
      setOrganisationError(true);
    } finally {
      setIsSaving(false);
    }
  }

  const personalInformationToggles = [
    {
      label: 'Full name',
      enabled: fullNameToggle,
      setter: setFullNameToggle,
      disabled: true,
      message: 'Mandatory field',
    },
    {
      label: 'Profile image',
      enabled: profileImageToggle,
      setter: setProfileImageToggle,
    },
    {
      label: 'Job title',
      enabled: jobTitleToggle,
      setter: setJobTitleToggle,
    },
    {
      label: 'Mobile number',
      enabled: mobileNumberToggle,
      setter: setMobileNumberToggle,
    },
    {
      label: 'Email address',
      enabled: emailToggle,
      setter: setEmailToggle,
    },
    {
      label: 'Social media links',
      enabled: socialMediaToggle,
      setter: setSocialMediaToggle,
    },
  ];

  const companyInformationToggles = [
    {
      label: 'Company name',
      enabled: companyNameToggle,
      setter: setCompanyNameToggle,
    },
    {
      label: 'Company website',
      enabled: companySiteToggle,
      setter: setCompanySiteToggle,
    },
    {
      label: 'Company phone number',
      enabled: companyPhoneToggle,
      setter: setCompanyPhoneToggle,
    },
    {
      label: 'Company address',
      enabled: companyAddressToggle,
      setter: setCompanyAddressToggle,
    },
  ];

  const sampleProfileUrl =
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=200&h=200&auto=format&fit=crop';

  const sampleAssets: IEmailSignatureAssets = {
    profile_image_url: sampleProfileUrl,
    qr_code_url: SampleQRCode,
  };
  const sampleProfile: IProfileSignature = {
    first_name: 'Sam',
    middle_name: null,
    last_name: 'Sample',
    job_title: 'Sales Director',
    email: 'sam.sample@sampleinc.com',
    mobile_number: '0412 345 678',
    photo: {
      id: 1,
      name: 'profile.jpg',
      file_size: 1024,
      small_url: sampleProfileUrl,
      medium_url: sampleProfileUrl,
      large_url: sampleProfileUrl,
      blur_url: sampleProfileUrl,
      blur_hash: 'LKJRU6o#?wi^xvM{4nof0Kn%-VkX',
      thumb_url: sampleProfileUrl,
      original_url: sampleProfileUrl,
      upload_url: sampleProfileUrl,
    },
    profile_page_url: 'https://profile.tapt.io/profile/sample',
    company_name: 'Sample Inc.',
    company_website: 'https://sampleinc.com',
    company_phone_number: '03 9999 9999',
    country: 'Australia',
    street_address: '81 Green Street',
    city: 'Cremorne',
    state: 'VIC',
    post_code: '3121',
    profile_social_links: [
      {
        order: 1,
        type: 'facebook',
        value: 'https://www.facebook.com/taptofficial/',
        personal: false,
      },
      {
        order: 2,
        type: 'instagram',
        value: 'https://www.instagram.com/taptofficial/',
        personal: false,
      },
      {
        order: 3,
        type: 'linkedin',
        value: 'https://www.linkedin.com/company/taptofficial/',
        personal: false,
      },
      {
        order: 4,
        type: 'tiktok',
        value: 'https://www.tiktok.com/@taptofficial/',
        personal: false,
      },
      {
        order: 5,
        type: 'pinterest',
        value: 'https://www.pinterest.com/taptofficial/',
        personal: false,
      },
      {
        order: 6,
        type: 'youtube',
        value: 'https://www.youtube.com/taptofficial/',
        personal: false,
      },
    ],
  };

  const signature: IEmailSignature = {
    show_full_name: fullNameToggle,
    show_profile_image: profileImageToggle,
    show_job_title: jobTitleToggle,
    show_mobile_number: mobileNumberToggle,
    show_email: emailToggle,
    show_social_links: socialMediaToggle,
    show_company_name: companyNameToggle,
    show_company_website: companySiteToggle,
    show_company_phone: companyPhoneToggle,
    show_company_address: companyAddressToggle,
    background_color: backgroundColor,
    text_color: textColor,
    primary_text_color: primaryTextColor,
    show_qr_code: showQRCode,
    show_banner: showBanner,
    disclaimer_text: disclaimer,
    banner_file: bannerImage,
    logo_file: companyLogo,
  };

  return (
    <div className="pt-8 pb-[33%] md:pb-[68px]" onFocusCapture={makeFormDirty}>
      {successMessage && (
        <div className="pb-8">
          <SuccessAlert message={successMessage} />
        </div>
      )}

      {organisationError && (
        <ErrorAlert message="Something went wrong. Please try again later" />
      )}

      <InfoPanelContainer
        title="Personal Information"
        information="Select which fields to include in the email signature."
      >
        <div className="space-y-4">
          {personalInformationToggles.map((field, index) => (
            <ToggleField
              key={index}
              label={field.label}
              enabled={field.enabled}
              setter={enabled => {
                field.setter(enabled);
                makeFormDirty();
              }}
              disabled={field.disabled}
              message={field.message}
            />
          ))}
        </div>
      </InfoPanelContainer>

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Company Information"
        information="Select which fields to include in the email signature."
      >
        <div className="space-y-4">
          {companyInformationToggles.map((field, index) => (
            <ToggleField
              key={index}
              label={field.label}
              enabled={field.enabled}
              setter={enabled => {
                field.setter(enabled);
                makeFormDirty();
              }}
            />
          ))}
        </div>
        <InfoPanelDivider />
        <UploadPhoto
          title="Banner image"
          fileFormatMessage={`Recommended dimensions: 524px x 200px\nMax file size 8MB (.jpeg or .png only)`}
          photo={bannerImage}
          setPhoto={setBannerImage}
          size="large"
          maxWidth={1048}
          maxHeight={400}
          aspectRatio={1048 / 400}
        />
        <InfoPanelDivider />
        <UploadPhoto
          title="Company logo mark/icon"
          fileFormatMessage={`Recommended dimensions: 100px x 100px\nMax file size 8MB (.jpeg or .png only)`}
          photo={companyLogo}
          setPhoto={setCompanyLogo}
          maxWidth={100}
          maxHeight={100}
        />
      </InfoPanelContainer>

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Colours"
        information="Select colours which have high contract to ensure maximum readability."
      >
        <div className="space-y-4">
          <div className="flex flex-col lg:flex-row md:gap-6 space-y-6 lg:space-y-0">
            <div className="flex flex-col">
              <p className="mb-2 font-medium">Primary text colour</p>
              <ColorPicker
                color={primaryTextColor}
                setColor={color => {
                  setPrimaryTextColor(color);
                  makeFormDirty();
                }}
              />
            </div>

            <div className="flex flex-col">
              <p className="mb-2 font-medium">Secondary text colour</p>
              <ColorPicker
                color={textColor}
                setColor={color => {
                  setTextColor(color);
                  makeFormDirty();
                }}
              />
            </div>
          </div>
        </div>
      </InfoPanelContainer>

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Other"
        information="Additional signature settings and content"
      >
        <div className="space-y-4">
          <ToggleField
            label="QR Code"
            enabled={showQRCode}
            setter={enabled => {
              setShowQRCode(enabled);
              makeFormDirty();
            }}
          />

          <div className="flex flex-col text-sm">
            <p className="mb-2">Disclaimer</p>
            <textarea
              className="min-h-[100px] rounded-md border border-gray-300 focus:ring-brand-500 focus:border-brand-500 p-2 text-sm "
              value={disclaimer}
              onChange={e => {
                setDisclaimer(e.target.value);
                makeFormDirty();
              }}
              placeholder="Add your email's legal or confidentiality disclaimer text here"
            />
          </div>
        </div>
      </InfoPanelContainer>

      <InfoPanelDivider />

      <InfoPanelContainer
        title="Preview Design"
        information="Review your email signature & ensure you are satisfied with the design. "
      >
        <div className="space-y-4 overflow-auto">
          <EmailSignaturePreview
            signature={signature}
            profile={sampleProfile}
            assets={sampleAssets}
            sampleMode
          />
        </div>
      </InfoPanelContainer>

      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            buttonText="Cancel"
            className="flex-1 xl:flex-none"
            kind={BUTTON_KIND.WHITE}
            onClick={() => window.location.reload()}
          />
          <Button
            buttonText="Save changes"
            className="flex-1 xl:flex-none"
            loading={isSaving}
            onClick={handleEmailSignatureSave}
          />
        </div>
      </InfoPanelFooter>

      {blocker.state === 'blocked' && (
        <UnsavedChangesModal
          proceed={async () => {
            await handleEmailSignatureSave();
            blocker.proceed();
          }}
          reset={blocker.reset}
          cancel={blocker.proceed}
          isLoading={isSaving}
        />
      )}
    </div>
  );
}

type SendEmailSignatureProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  checkedProfiles: number[];
  onSuccessCallback?: () => void;
};

export function SendEmailSignatureModal({
  isOpen,
  setIsOpen,
  checkedProfiles,
  onSuccessCallback,
}: SendEmailSignatureProps) {
  const { orgID } = useAuth();

  const [isSending, setIsSending] = useState(false);

  async function sendPass() {
    if (orgID === undefined) {
      return;
    }

    setIsSending(true);

    await organisationsAPI.shareEmailSignature(orgID, {
      profile_ids: checkedProfiles,
    });

    setIsSending(false);
    setIsOpen(false);
    onSuccessCallback?.();
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle="Share email signature with profile holders via email"
      dialogDescription="An email will be sent to the selected profile holders inviting them to add their Tapt email signature."
      successButtonText="Send email signature"
      onSuccess={sendPass}
      isLoading={isSending}
    />
  );
}
