import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import { useBlocker, useNavigate } from 'react-router-dom';

import { InformationCircleIcon } from '@heroicons/react/24/outline';

import organisationsAPI from '@/api/organisations';
import DefaultProfilePicture from '@/assets/images/DefaultProfilePicture.png';
import QRCode from '@/assets/images/qr-code.png';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import TaptUnion from '@/components/Icons/TaptUnion';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Modal from '@/components/Modals/Modal';
import UploadPhoto from '@/components/UploadPhoto';
import { isDarkColor } from '@/helpers/strings';
import useAuth from '@/hooks/useAuth';
import IFile from '@/types/IFile';
import { IDigitalWalletDesignRequest } from '@/types/IOrganisation';

import ColorPicker from '../ColorPicker';
import { UnsavedChangesModal } from '../UnsavedChangesPrompt';

const DEFAULT_BACKGROUND_COLOR = '#FFFFFF';
const DEFAULT_TEXT_COLOR = '#000000';
export function DigitalWallet() {
  const { orgID } = useAuth();
  const navigate = useNavigate();

  const [isDirty, setIsDirty] = useState(false);
  const blocker = useBlocker(isDirty);

  const { data: digitalWalletDesign } = useQuery(
    ['showDigitalWalletDesign', orgID],
    showDigitalWalletDesign,
    {
      enabled: orgID !== undefined,
    },
  );

  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [backgroundColorApple, setBackgroundColorApple] = useState(
    DEFAULT_BACKGROUND_COLOR,
  );
  const [textColorApple, setTextColorApple] = useState(DEFAULT_TEXT_COLOR);

  const [bannerImage, setBannerImage] = useState<IFile | undefined>(undefined);
  const [companyLogoGoogle, setCompanyLogoGoogle] = useState<IFile | undefined>(
    undefined,
  );
  const [companyLogoApple, setCompanyLogoApple] = useState<IFile | undefined>(
    undefined,
  );

  const [backgroundColorGoogle, setBackgroundColorGoogle] = useState(
    DEFAULT_BACKGROUND_COLOR,
  );

  useEffect(() => {
    if (digitalWalletDesign) {
      setBackgroundColorApple(
        digitalWalletDesign.apple.background_color || DEFAULT_BACKGROUND_COLOR,
      );
      setTextColorApple(digitalWalletDesign.apple.text_color);
      setCompanyLogoApple(digitalWalletDesign.apple.logo);

      setBackgroundColorGoogle(
        digitalWalletDesign.google.background_color || DEFAULT_BACKGROUND_COLOR,
      );
      setBannerImage(digitalWalletDesign.google.banner);
      setCompanyLogoGoogle(digitalWalletDesign.google.logo);
    }
  }, [digitalWalletDesign]);

  async function showDigitalWalletDesign() {
    if (orgID === undefined) {
      return;
    }

    const res = await organisationsAPI.getDigitalWalletDesign(orgID);
    return res.data;
  }

  const makeFormDirty = () => {
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const handleSave = async () => {
    if (orgID === undefined) {
      return;
    }

    setIsSaving(true);
    setSuccessMessage(undefined);
    setErrorMessage(undefined);

    if (isDirty) {
      const payload: IDigitalWalletDesignRequest = {
        apple: {
          background_color: backgroundColorApple,
          text_color: textColorApple,
          logo_file_id: companyLogoApple?.id || null,
        },
        google: {
          background_color: backgroundColorGoogle,
          banner_image_file_id: bannerImage?.id || null,
          logo_file_id: companyLogoGoogle?.id || null,
        },
      };
      const res = await organisationsAPI.updateDigitalWalletDesign(
        orgID,
        payload,
      );

      if (res.status === 200) {
        setSuccessMessage('Digital wallet design updated successfully');
        setIsDirty(false);
      } else {
        setErrorMessage('Error updating digital wallet design');
      }

      setIsSaving(false);
    }
  };

  return (
    <div className="pt-8 pb-[33%] md:pb-[68px]" onFocusCapture={makeFormDirty}>
      {successMessage && (
        <div className="mb-5">
          <SuccessAlert message={successMessage} />
        </div>
      )}

      {errorMessage && (
        <div className="mb-5">
          <ErrorAlert message={errorMessage} />
        </div>
      )}
      <div className="flex flex-col xl:flex-row justify-between gap-6">
        <div className="flex-1 flex flex-col border border-gray-300 rounded-md p-6 bg-white h-fit">
          <span className="font-medium text-xl text-gray-900">
            Apple Wallet Customisation
          </span>
          <InfoPanelDivider />
          <div className="flex flex-col xl:flex-row gap-y-8 justify-between">
            <div className="flex flex-col md:flex-row gap-6">
              <div className="flex flex-col space-y-2">
                <span className="text-sm font-medium text-gray-900">
                  Background colour
                </span>
                {digitalWalletDesign === undefined ? (
                  <Skeleton width={200} height={42} />
                ) : (
                  <ColorPicker
                    color={backgroundColorApple}
                    setColor={setBackgroundColorApple}
                  />
                )}
              </div>
              <div className="flex flex-col space-y-2">
                <span className="text-sm font-medium text-gray-900">
                  Text colour
                </span>
                {digitalWalletDesign === undefined ? (
                  <Skeleton width={200} height={42} />
                ) : (
                  <ColorPicker
                    color={textColorApple}
                    setColor={setTextColorApple}
                  />
                )}
              </div>
            </div>
            <Button
              buttonText="Reset colours"
              className="self-end"
              kind={BUTTON_KIND.WHITE}
              onClick={() => {
                if (digitalWalletDesign) {
                  setBackgroundColorApple(
                    digitalWalletDesign.apple.background_color,
                  );
                  setTextColorApple(digitalWalletDesign.apple.text_color);
                }
              }}
              loading={digitalWalletDesign === undefined}
              disabled={
                backgroundColorApple ===
                  digitalWalletDesign?.apple.background_color &&
                textColorApple === digitalWalletDesign.apple.text_color
              }
            />
          </div>
          <InfoPanelDivider />
          <UploadPhoto
            title="Company mark logo/icon"
            fileFormatMessage={`Recommended dimensions 600px x 600px\nMax file size 8MB (.jpeg or .png only)`}
            photo={companyLogoApple}
            setPhoto={setCompanyLogoApple}
            maxWidth={660}
            maxHeight={660}
          />
        </div>
        <div className="flex items-center justify-center border border-gray-300 rounded-md bg-white p-6 h-fit">
          {digitalWalletDesign ? (
            <AppleGenericPass
              payload={{
                title: 'Sample Inc',
                header: 'John Doe',
                subheading: 'Marketing Manager',
                logo: companyLogoApple ? companyLogoApple.small_url : null,
                backgroundColor: backgroundColorApple,
                textColor: textColorApple,
              }}
            />
          ) : (
            <Skeleton width={330} height={440} />
          )}
        </div>
      </div>
      <InfoPanelDivider />
      <div className="flex flex-col xl:flex-row justify-between gap-6">
        <div className="flex-1 flex flex-col rounded-md border border-gray-300 bg-white p-6 h-fit">
          <span className="font-medium text-xl text-gray-900">
            Google Wallet Customisation
          </span>
          <InfoPanelDivider />
          <div className="flex flex-col xl:flex-row justify-between gap-y-8">
            <div className="flex gap-6">
              <div className="flex flex-col space-y-2">
                <span className="text-sm font-medium text-gray-900">
                  Background colour
                </span>
                {digitalWalletDesign === undefined ? (
                  <Skeleton width={200} height={42} />
                ) : (
                  <ColorPicker
                    color={backgroundColorGoogle}
                    setColor={setBackgroundColorGoogle}
                  />
                )}
              </div>
            </div>
            <Button
              buttonText="Reset colours"
              className="self-end"
              kind={BUTTON_KIND.WHITE}
              onClick={() => {
                if (digitalWalletDesign) {
                  setBackgroundColorGoogle(
                    digitalWalletDesign.google.background_color,
                  );
                }
              }}
              loading={digitalWalletDesign === undefined}
              disabled={
                backgroundColorGoogle ===
                digitalWalletDesign?.google.background_color
              }
            />
          </div>
          <InfoPanelDivider />
          <UploadPhoto
            title="Banner image"
            fileFormatMessage={`Recommended dimensions: 1032px x 336px\nMax file size 8MB (.jpeg or .png only)`}
            photo={bannerImage}
            setPhoto={setBannerImage}
            size="large"
            maxWidth={1032}
            maxHeight={336}
            aspectRatio={1032 / 336}
          />
          <InfoPanelDivider />
          <UploadPhoto
            title="Company logo mark/icon"
            fileFormatMessage={`Recommended dimensions: 660px x 660px\nMax file size 8MB (.jpeg or .png only)`}
            photo={companyLogoGoogle}
            setPhoto={setCompanyLogoGoogle}
            maxWidth={660}
            maxHeight={660}
          />
        </div>
        <div className="flex items-center justify-center border border-gray-300 rounded-md bg-white p-6 h-fit">
          {digitalWalletDesign ? (
            <GoogleGenericPass
              payload={{
                title: 'Sample Inc',
                subheading: 'Marketing Manager',
                header: 'John Doe',
                heroImage: bannerImage ? bannerImage.small_url : null,
                logo: companyLogoGoogle ? companyLogoGoogle.small_url : null,
                backgroundColor: backgroundColorGoogle,
              }}
            />
          ) : (
            <Skeleton width={270} className="h-full" />
          )}
        </div>
      </div>
      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            buttonText="Cancel"
            className="flex-1 xl:flex-none"
            kind={BUTTON_KIND.WHITE}
            onClick={() => navigate(-1)}
          />
          <Button
            buttonText="Save changes"
            onClick={handleSave}
            className="flex-1 xl:flex-none"
            loading={isSaving}
          />
        </div>
      </InfoPanelFooter>
      {blocker.state === 'blocked' && (
        <UnsavedChangesModal
          proceed={async () => {
            await handleSave();
            blocker.proceed();
          }}
          reset={blocker.reset}
          cancel={blocker.proceed}
          isLoading={isSaving}
        />
      )}
    </div>
  );
}

type GoogleWalletPassProps = {
  payload: GoogleWalletPayload;
};

type GoogleWalletPayload = {
  logo: string | null;
  title: string;
  backgroundColor: string;
  subheading?: string;
  header?: string;
  heroImage: string | null;
};

// reference: https://developers.google.com/wallet/generic/resources/pass-builder
function GoogleGenericPass({ payload }: GoogleWalletPassProps) {
  const { backgroundColor, title, subheading, header, logo, heroImage } =
    payload;

  const textColor = isDarkColor(backgroundColor) ? 'white' : 'black';

  return (
    <div
      className="w-[300px] shadow-xl rounded-md overflow-hidden"
      style={{ color: textColor, backgroundColor }}
    >
      {/* title bar */}
      <div className="flex items-center space-x-3 px-3 pt-3">
        {/* logo */}
        <span className="w-6 h-6 rounded-full bg-white flex-shrink-0 inline-flex items-center justify-center overflow-hidden">
          {logo ? (
            <img src={logo} />
          ) : (
            <TaptUnion className="text-brand-500" width="20" height="20" />
          )}
        </span>
        {/* title */}
        <span className="text-sm font-medium">{title}</span>
      </div>
      {/* divider */}
      <div className="h-[1px]" />
      {/* spacer */}
      <div className="h-[10px]" />
      {/* header */}
      <div className="flex flex-col px-3">
        {subheading && <span className="text-sm">{subheading}</span>}
        {header && <span className="text-xl">{header}</span>}
      </div>

      {/* spacer */}
      <div className="h-1.5" />
      {/* spacer */}
      <div className="h-[10px]" />
      {/* qr code */}
      <div className="py-[5px]">
        <div className="p-[14px] bg-white rounded-xl place-self-center">
          <img src={QRCode} className="w-28 h-28" alt="Profile QR Code" />
        </div>
      </div>
      {/* spacer */}
      <div className="h-[10px]" />
      {/* hero image */}
      {heroImage && (
        <div className="h-[88px]">
          <img src={heroImage} className="w-full h-full object-cover" />
        </div>
      )}
    </div>
  );
}

type AppleWalletPassProps = {
  payload: AppleWalletPayload;
};

type AppleWalletPayload = Omit<GoogleWalletPayload, 'heroImage'> & {
  textColor: string;
};

// references https://developer.apple.com/design/human-interface-guidelines/wallet#Generic-passes
function AppleGenericPass({ payload }: AppleWalletPassProps) {
  const {
    backgroundColor,
    logo,
    textColor,
    header: name,
    subheading: jobTitle,
    title: companyName,
  } = payload;

  const logoColor = isDarkColor(backgroundColor) ? 'white' : 'black';

  return (
    <div
      className="h-[465px] w-[300px] flex flex-col justify-between rounded-md shadow-xl"
      style={{ backgroundColor, color: textColor }}
    >
      <div className="space-y-6">
        <div className="flex items-center px-4 pt-1">
          {/* logo */}
          {logo ? (
            <div className="w-12 h-12 rounded-full overflow-hidden">
              <img src={logo} width={48} height={48} />
            </div>
          ) : (
            <div className="pt-3">
              <TaptUnion color={logoColor} width="24" height="24" />
            </div>
          )}
        </div>

        <div className="flex justify-between px-4">
          <div className="space-y-10">
            <div className="flex flex-col font-medium">
              <span className="uppercase text-xs">Name</span>
              {name && <span className="text-2xl">{name}</span>}
            </div>
            <div className="space-y-4">
              <div className="flex flex-col text-xs">
                <span className="uppercase font-medium">Job title</span>
                {jobTitle && <span className="font-light">{jobTitle}</span>}
              </div>
              <div className="flex flex-col text-xs">
                <span className="uppercase font-medium">Company</span>
                {companyName && (
                  <span className="font-light">{companyName}</span>
                )}
              </div>
            </div>
          </div>
          <div className="w-20 h-20 rounded-full overflow-hidden">
            <img
              src={DefaultProfilePicture}
              className="w-20 h-20"
              alt="Profile photo"
            />
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="p-2 rounded-md bg-white place-self-center">
          <img src={QRCode} className="w-36 h-36" alt="Profile QR Code" />
        </div>
      </div>
    </div>
  );
}

type SendPassModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  checkedProfiles: number[];
  onSuccessCallback?: () => void;
};

export function SendPassModal({
  isOpen,
  setIsOpen,
  checkedProfiles,
  onSuccessCallback,
}: SendPassModalProps) {
  const { orgID } = useAuth();

  const [isSending, setIsSending] = useState(false);

  async function sendPass() {
    if (orgID === undefined) {
      return;
    }

    setIsSending(true);

    await organisationsAPI.shareDigitalWallet(orgID, {
      profile_ids: checkedProfiles,
    });

    setIsSending(false);
    setIsOpen(false);
    onSuccessCallback?.();
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle="Share digital wallet pass with profile holders via email"
      dialogDescription="An email will be sent to the selected profile holders inviting them to add their Tapt profile to their digital wallet (either Apple Wallet or Google Wallet)."
      successButtonText="Send pass"
      onSuccess={sendPass}
      isLoading={isSending}
    />
  );
}

export function DigitalWalletBanner() {
  return (
    <div className="bg-amber-50 text-sm flex text-amber-800 items-center justify-center font-medium gap-x-2 border border-amber-300 rounded-md p-2 xl:w-max self-end">
      <InformationCircleIcon className="w-5 h-5 text-amber-500" />
      All profiles (including those in groups) will have the same digital wallet
      design.
    </div>
  );
}
