import React, { Fragment, JSX, useEffect, useState } from 'react';

import { Menu, Transition } from '@headlessui/react';
import {
  MagnifyingGlassIcon as SearchIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { useDebounce } from 'usehooks-ts';

import Badge, { BADGE_KIND } from './Badge';
import Input from './Input';

export type Item = {
  id: number;
  title: string;
};

type Props = {
  title: string;
  items?: Item[];
  searchItems?: (query: string) => Promise<Item[]>;
  fetchData?: (selectedValues: number[]) => void;
  icon?: JSX.Element;
  emptyMessage?: string;
};

const SearchableInput: React.FC<Props> = ({
  title,
  items,
  searchItems,
  fetchData,
  icon = null,
  emptyMessage = 'No results',
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredValues, setFilteredValues] = useState<Item[]>(items ?? []);
  const [selectedValues, setSelectedValues] = useState<number[]>([]);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    const getData = async () => {
      const remoteItems = await searchItems?.(debouncedSearchTerm);
      setFilteredValues(remoteItems || []);
    };
    getData();
  }, [debouncedSearchTerm, searchItems]);

  const handleQueryChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleClose = () => {
    setSearchTerm('');
    setFilteredValues(items ?? []);

    fetchData?.(selectedValues);
  };

  const renderItem = (option: Item, selected = false) => {
    return (
      <Menu.Item key={option.id}>
        <div
          key={option.id}
          className="flex items-center py-2 pl-3 pr-4 hover:bg-gray-100 cursor-pointer"
          onClick={e => {
            e.preventDefault(); // NOTE: This is needed to prevent the menu from closing
            setSelectedValues(
              selectedValues.includes(option.id)
                ? selectedValues.filter(value => value !== option.id)
                : [...selectedValues, option.id],
            );
          }}
          role="button"
          tabIndex={0}
        >
          <input
            type="checkbox"
            className="h-4 w-4 text-brand-500 focus:ring-brand-500 border-gray-300 rounded"
            checked={selected ? true : selectedValues.includes(option.id)}
            key={selectedValues.length}
            readOnly
          />
          <span className="ml-3 block text-sm text-gray-700 truncate">
            {option.title}
          </span>
        </div>
      </Menu.Item>
    );
  };

  return (
    <Menu as="div" className="relative inline-block text-left lg:mt-0 z-20">
      <div>
        <Menu.Button className="inline-flex items-center justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-brand-500 focus:border-brand-500">
          {icon}
          {title}
          {selectedValues.length > 0 && (
            <>
              <Badge
                kind={BADGE_KIND.GRAY}
                className="ml-2"
                text={selectedValues.length.toString()}
              />

              <XMarkIcon
                className="-mr-1 ml-2 h-4 w-4 text-gray-400"
                aria-hidden="true"
                onClick={e => {
                  e.stopPropagation();
                  setFilteredValues(items ?? []);
                  setSelectedValues([]);

                  fetchData?.([]);
                }}
              />
            </>
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        afterLeave={handleClose}
      >
        <Menu.Items
          className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-hidden"
          style={{
            maxHeight: '250px',
            maxWidth: '200px',
            overflowY: items?.length === 0 ? 'auto' : 'scroll',
          }}
        >
          {items && items.length === 0 ? (
            <div className="text-gray-700 px-4 py-3 text-sm">
              {emptyMessage}
            </div>
          ) : (
            <div className="pb-1">
              <div className="sticky top-0 bg-white z-20 px-3 py-2">
                <Input
                  id="search"
                  label=""
                  description=""
                  placeholder="Search"
                  type="text"
                  autocomplete="off"
                  value={searchTerm}
                  onChange={handleQueryChange}
                  onKeyDown={e => {
                    if (e.code === 'Space') {
                      e.stopPropagation();
                    }
                  }}
                  icon={SearchIcon}
                />
              </div>
              {items === undefined && (
                <div className="text-gray-500 text-center my-4 italic text-sm">
                  Loading...
                </div>
              )}
              {(filteredValues || []).map(option => renderItem(option))}
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default SearchableInput;
