import { IIcon } from '@/types/IIcon';

const ChatAltIcon = ({ className }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
    >
      <g filter="url(#filter0_d_6773_79577)">
        <path
          d="M11.6667 14.584H11.6813M17.5 14.584H17.5146M23.3333 14.584H23.3479M13.125 23.334H7.29167C6.51812 23.334 5.77625 23.0267 5.22927 22.4797C4.68229 21.9327 4.375 21.1909 4.375 20.4173V8.75065C4.375 7.9771 4.68229 7.23524 5.22927 6.68826C5.77625 6.14128 6.51812 5.83398 7.29167 5.83398H27.7083C28.4819 5.83398 29.2237 6.14128 29.7707 6.68826C30.3177 7.23524 30.625 7.9771 30.625 8.75065V20.4173C30.625 21.1909 30.3177 21.9327 29.7707 22.4797C29.2237 23.0267 28.4819 23.334 27.7083 23.334H20.4167L13.125 30.6256V23.334Z"
          stroke="#D1D5DB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6773_79577"
          x="-2"
          y="-1"
          width="39"
          height="39"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6773_79577"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6773_79577"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ChatAltIcon;
