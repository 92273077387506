import { z } from 'zod';

export default interface IFile {
  blur_url: string;
  blur_hash?: string | null;
  file?: {
    file_name: string;
    updated_at: string;
  };
  id: number;
  large_url: string;
  medium_url: string;
  name: string;
  original_url: string;
  small_url: string;
  thumb_url: string;
  upload_url: string;
  file_size?: number | null;
}

export const FileSchema = z.object({
  blur_url: z.string(),
  file: z
    .object({
      file_name: z.string(),
      updated_at: z.string(),
    })
    .optional(),
  id: z.number(),
  large_url: z.string(),
  medium_url: z.string(),
  name: z.string(),
  original_url: z.string(),
  small_url: z.string(),
  thumb_url: z.string(),
  upload_url: z.string(),
  file_size: z.number().nullish(),
});
