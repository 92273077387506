import { useEffect, useState } from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import { ClickAwayListener } from '@mui/material';
import { useDebounce } from 'usehooks-ts';

import { ErrorAlert } from '@/components/Alert';
import Input from '@/components/Input';
import ToggleField from '@/components/ToggleField';
import MESSAGES from '@/constants/messages-en';

interface ICompanyInfo {
  companyName: string;
  companyNameError?: boolean;
  companyWebsite: string;
  companyWebsiteError?: boolean;
  companyPhoneNumber: string;
  extraCompanyPhoneNumbers: string[];
  companyPhoneNumberError?: boolean;
  country: string;
  streetAddress: string;
  streetAddressError?: boolean;
  city: string;
  stateProvince: string;
  postCode: string;
  locationLatLng?: google.maps.LatLngLiteral;
  mapEnabled: boolean;
  disabled?: boolean;
  shared?: boolean;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  setCompanyWebsite: React.Dispatch<React.SetStateAction<string>>;
  setCompanyPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setExtraCompanyPhoneNumbers: React.Dispatch<React.SetStateAction<string[]>>;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  setStreetAddress: React.Dispatch<React.SetStateAction<string>>;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  setStateProvince: React.Dispatch<React.SetStateAction<string>>;
  setPostCode: React.Dispatch<React.SetStateAction<string>>;
  setLocationLatLng: React.Dispatch<google.maps.LatLngLiteral>;
  setMapEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

type ExtraMobileNumberProps = {
  mobileNumber: string;
  mobileNumbers: string[];
  setMobileNumbers: React.Dispatch<React.SetStateAction<string[]>>;
  index: number;
  disabled: boolean;
};

const ExtraMobileNumber: React.FC<ExtraMobileNumberProps> = ({
  mobileNumber,
  mobileNumbers,
  setMobileNumbers,
  index,
  disabled,
}) => {
  return (
    <div key={index} className="col-span-6 sm:col-span-3">
      <Input
        label="Direct phone number"
        type="text"
        value={mobileNumber}
        onChange={value => {
          setMobileNumbers(
            Object.assign([...mobileNumbers], { [index]: value }),
          );
        }}
        disabled={disabled}
        copy={disabled}
        placeholder="Enter direct phone number"
        rightIcon={
          <MinusCircleIcon className="text-red-450 z-20 h-5 w-5 cursor-pointer" />
        }
        handleRightIconClick={() => {
          setMobileNumbers(mobileNumbers.filter((_, i) => i !== index));
        }}
      />
    </div>
  );
};

export default function CompanyInfoForm({
  companyName,
  companyNameError,
  companyWebsite,
  companyWebsiteError,
  companyPhoneNumber,
  extraCompanyPhoneNumbers,
  companyPhoneNumberError,
  country,
  streetAddress,
  streetAddressError,
  city,
  stateProvince,
  postCode,
  mapEnabled,
  disabled,
  shared,
  setCompanyName,
  setCompanyWebsite,
  setCompanyPhoneNumber,
  setExtraCompanyPhoneNumbers,
  setCountry,
  setStreetAddress,
  setCity,
  setStateProvince,
  setPostCode,
  setLocationLatLng,
  setMapEnabled,
}: ICompanyInfo) {
  const [showPredictions, setShowPredictions] = useState(false);
  const [predictionsEnabled, setPredictionsEnabled] = useState(false);

  const extractAddress = (place: google.maps.places.PlaceResult) => {
    const address = place.address_components;

    if (!address) return;

    const streetNumber = address.find(component =>
      component.types.includes('street_number'),
    );
    const streetName = address.find(component =>
      component.types.includes('route'),
    );
    const localCity = address.find(component =>
      component.types.includes('administrative_area_level_2'),
    );
    const state = address.find(component =>
      component.types.includes('administrative_area_level_1'),
    );
    const localCountry = address.find(component =>
      component.types.includes('country'),
    );
    const postcode = address.find(component =>
      component.types.includes('postal_code'),
    );
    if (streetName)
      setStreetAddress(
        `${[streetNumber?.long_name, streetName?.long_name].join(' ')}`,
      );
    if (localCity) setCity(localCity?.long_name);
    if (state) setStateProvince(state?.long_name);
    if (localCountry) setCountry(localCountry?.long_name);
    if (postcode) setPostCode(postcode?.long_name);
  };
  const debouncedStreetAddress = useDebounce(streetAddress, 500);
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
  } = useGoogle({
    apiKey:
      (import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string | undefined) ||
      'AIzaSyAGnYxI8HlblxV7i9LJI9jSb8uhjpxXAUI',
    options: {
      input: debouncedStreetAddress,
      types: ['address'],
    },
  });

  useEffect(() => {
    if (debouncedStreetAddress) {
      getPlacePredictions({ input: debouncedStreetAddress });
      setShowPredictions(true);
    }
  }, [debouncedStreetAddress]);

  return (
    <>
      {companyNameError && <ErrorAlert message={MESSAGES.error.companyName} />}
      {companyWebsiteError && (
        <ErrorAlert message={MESSAGES.error.companyWebsite} />
      )}
      {companyPhoneNumberError && (
        <ErrorAlert message={MESSAGES.error.companyPhoneNumber} />
      )}
      {streetAddressError && (
        <ErrorAlert message={MESSAGES.error.streetAddress} />
      )}
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <Input
            label="Company name"
            type="text"
            value={companyName}
            onChange={setCompanyName}
            error={companyNameError}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && companyName === 'Click to edit or clear')
                setCompanyName('');
            }}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <Input
            preLabel="https://"
            label="Company website"
            type="text"
            value={companyWebsite}
            onChange={setCompanyWebsite}
            error={companyWebsiteError}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && companyWebsite === 'Click to edit or clear')
                setCompanyWebsite('');
            }}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <Input
            label="Company phone number"
            placeholder="Enter company number"
            type="text"
            value={companyPhoneNumber}
            onChange={setCompanyPhoneNumber}
            error={companyPhoneNumberError}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && companyPhoneNumber === 'Click to edit or clear')
                setCompanyPhoneNumber('');
            }}
            rightIcon={
              shared ? null : (
                <PlusCircleIcon className="text-brand-500 z-20 h-5 w-5 cursor-pointer" />
              )
            }
            handleRightIconClick={() => {
              setExtraCompanyPhoneNumbers([...extraCompanyPhoneNumbers, '']);
            }}
          />
        </div>
        {extraCompanyPhoneNumbers.map((extraMobileNumber, index) => (
          <ExtraMobileNumber
            mobileNumber={extraMobileNumber}
            mobileNumbers={extraCompanyPhoneNumbers}
            setMobileNumbers={setExtraCompanyPhoneNumbers}
            index={index}
            disabled={!!disabled}
          />
        ))}

        <div className="col-span-6">
          <Input
            label="Street address"
            type="text"
            value={streetAddress}
            onChange={setStreetAddress}
            error={streetAddressError}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && streetAddress === 'Click to edit or clear')
                setStreetAddress('');
            }}
          />
        </div>

        {false && (
          <div className="col-span-6 relative">
            <ClickAwayListener onClickAway={() => setPredictionsEnabled(false)}>
              <div>
                <Input
                  label="Street address"
                  type="text"
                  value={streetAddress}
                  onChange={setStreetAddress}
                  error={streetAddressError}
                  disabled={disabled}
                  copy={disabled}
                  onFocus={() => {
                    setPredictionsEnabled(true);
                    if (shared && streetAddress === 'Click to edit or clear')
                      setStreetAddress('');
                  }}
                  onKeyDown={e => {
                    if (!['Escape', 'Tab', 'Enter'].includes(e.key)) return;

                    setShowPredictions(false);
                  }}
                />
              </div>
            </ClickAwayListener>
            {showPredictions &&
              predictionsEnabled &&
              !isPlacePredictionsLoading &&
              (placePredictions || []).length > 0 && (
                <div className="absolute bg-white z-10 rounded-md shadow-floating mt-3 w-full">
                  {placePredictions?.map(prediction => (
                    <div
                      onClick={() => {
                        placesService?.getDetails(
                          { placeId: prediction.place_id },
                          (place, status) => {
                            if (status !== 'OK' || !place) return;
                            extractAddress(place);
                            const [lat, lng] = [
                              place.geometry?.location?.lat?.(),
                              place.geometry?.location?.lng?.(),
                            ];

                            setPredictionsEnabled(false);

                            if (!lat || !lng) return;
                            setLocationLatLng({
                              lat,
                              lng,
                            });
                          },
                        );
                      }}
                      key={prediction.place_id}
                      className="text-md px-4 py-2 border-b-gray-100 border hover:bg-gray-100 cursor-pointer last:border-b-0 last:rounded-b-md first:rounded-t-sm"
                    >
                      {prediction.description}
                    </div>
                  ))}
                </div>
              )}
          </div>
        )}

        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
          <Input
            label="City"
            type="text"
            value={city}
            onChange={setCity}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && city === 'Click to edit or clear') setCity('');
            }}
          />
        </div>

        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <Input
            label="State / Province"
            type="text"
            value={stateProvince}
            onChange={setStateProvince}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && stateProvince === 'Click to edit or clear')
                setStateProvince('');
            }}
          />
        </div>

        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <Input
            label="Postcode"
            type="text"
            value={postCode}
            onChange={setPostCode}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && postCode === 'Click to edit or clear')
                setPostCode('');
            }}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <Input
            label="Country"
            type="text"
            value={country}
            onChange={setCountry}
            disabled={disabled}
            copy={disabled}
            onFocus={() => {
              if (shared && country === 'Click to edit or clear')
                setCountry('');
            }}
          />
        </div>
      </div>

      {false && (
        <div className="col-span-6 sm:col-span-12 mt-6">
          <ToggleField
            label="Enable map in profile"
            enabled={mapEnabled}
            setter={setMapEnabled}
            description="Include a map with a location marker on your profile."
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
}
