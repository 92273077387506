import { RefObject, createRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { cx } from '@emotion/css';

type Tab = {
  name: string;
  key: string;
  href: string;
  ref: RefObject<HTMLAnchorElement | null>;
};

type PseudoTabs = {
  activeTab: string;
  tabs: Tab[];
};

export const ProfilesGroupsPseudoTabs: Tab[] = [
  {
    key: 'profiles',
    name: 'All Profiles',
    href: '/',
    ref: createRef(),
  },
  {
    key: 'groups',
    name: 'Groups',
    href: '/groups',
    ref: createRef(),
  },
];

export const TemplatesPseudoTabs: Tab[] = [
  {
    key: 'profile-design',
    name: 'Profile Design',
    href: '/templates/profile-design',
    ref: createRef(),
  },
  {
    key: 'contact-exchange',
    name: 'Contact Exchange',
    href: '/templates/contact-exchange',
    ref: createRef(),
  },
  {
    key: 'email-signature',
    name: 'Email Signature',
    href: '/templates/email-signature',
    ref: createRef(),
  },
  {
    key: 'digital-wallet',
    name: 'Digital Wallet',
    href: '/templates/digital-wallet',
    ref: createRef(),
  },
];

export function PseudoTabs({ activeTab, tabs }: PseudoTabs) {
  useEffect(() => {
    const activeTabRef = tabs.find(tab => tab.key === activeTab)?.ref;
    if (activeTabRef?.current) {
      activeTabRef.current.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
        block: 'nearest',
      });
    }
  }, [activeTab]);

  return (
    <div className="border-b border-b-gray-200 grid gap-8 grid-flow-col justify-start overflow-x-scroll hide-scrollbar">
      {tabs.map((tab, index) => (
        <Link
          key={index}
          to={tab.href}
          ref={tab.ref}
          className={cx(
            'text-gray-500 text-lg py-2 hover:text-gray-900 text-nowrap',
            activeTab === tab.key ? 'font-medium' : 'font-normal',
            {
              'text-gray-900 border-b-2 border-b-gray-900':
                activeTab === tab.key,
            },
          )}
        >
          {tab.name}
        </Link>
      ))}
    </div>
  );
}
