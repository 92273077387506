import { Dispatch, SetStateAction } from 'react';

import { ArrowTopRightOnSquareIcon as ExternalLinkIcon } from '@heroicons/react/24/outline';
import { Box } from '@mui/material';

import IDesign from '@/types/IDesign';
import { IOrderDetailUpdate } from '@/types/IOrderDetail';
import IOrderV2, { OrderTag } from '@/types/IOrderV2';

import DesignInformation from './DesignInformation';
import OrderInformation from './OrderInformation';
import SalesInformation from './SalesInformation';

interface IOrderDetailInformationBox {
  handleClickLinkedOrg: (orgId: number) => void;
  orgID: number;
  setIsMoveOrder: Dispatch<SetStateAction<boolean>>;
  inputAlternateEmail: string;
  setInputAlternateEmail: Dispatch<SetStateAction<string>>;
  setIsAlternateEmailValid: Dispatch<SetStateAction<boolean>>;
  orderDetailUpdate: IOrderDetailUpdate;
  setOrderDetailUpdate: Dispatch<SetStateAction<IOrderDetailUpdate>>;
  setIsUploadDesign: Dispatch<SetStateAction<boolean>>;
  orderDesign: IDesign[];

  organisationPastOrder: IOrderV2[];
  orderNumber: string;
  customerName: string;
  customerEmail: string;
  orderStatusUrl: string;
  updateOrderStatus: string;
  setUpdateOrderStatus: Dispatch<SetStateAction<string>>;
  orderId: number;
  orderTag: string | undefined;
  setOrderTag: Dispatch<SetStateAction<OrderTag | undefined>>;
  order: IOrderV2;
}

export default function OrderDetailInformationBox({
  handleClickLinkedOrg,
  orgID,
  setIsMoveOrder,
  inputAlternateEmail,
  setInputAlternateEmail,
  setIsAlternateEmailValid,
  orderDetailUpdate,
  setOrderDetailUpdate,
  setIsUploadDesign,
  orderDesign,
  organisationPastOrder,
  orderNumber,
  orderStatusUrl,
  updateOrderStatus,
  setUpdateOrderStatus,
  orderId,
  orderTag,
  setOrderTag,
  customerName,
  customerEmail,
  order,
}: IOrderDetailInformationBox) {
  const Divider = (
    <div className="hidden sm:block" aria-hidden="true">
      <div className="py-2 md:py-4">
        <div className="border-t border-gray-200" />
      </div>
    </div>
  );

  return (
    <Box className="">
      <Box className="flex w-full items-center justify-start text-lg leading-6 font-poppins gap-1">
        Order #{orderNumber}
        <ExternalLinkIcon
          width={18}
          height={18}
          strokeWidth={2}
          className="cursor-pointer"
          onClick={() => window.open(orderStatusUrl, '_blank')}
        >
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M7 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17H13C13.5304 17 14.0391 16.7893 14.4142 16.4142C14.7893 16.0391 15 15.5304 15 15V11M11 1H17M17 1V7M17 1L7 11"
            stroke="#3F3F46"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </ExternalLinkIcon>
      </Box>
      <SalesInformation
        inputAlternateEmail={inputAlternateEmail}
        setInputAlternateEmail={setInputAlternateEmail}
        setIsAlternateEmailValid={setIsAlternateEmailValid}
        orderDetailUpdate={orderDetailUpdate}
        setOrderDetailUpdate={setOrderDetailUpdate}
        updateOrderStatus={updateOrderStatus}
        setUpdateOrderStatus={setUpdateOrderStatus}
        orderId={orderId}
        orderTag={orderTag}
        setOrderTag={setOrderTag}
      />
      {Divider}
      <OrderInformation
        orgID={orgID}
        handleClickLinkedOrg={handleClickLinkedOrg}
        setIsMoveOrder={setIsMoveOrder}
        organisationPastOrder={organisationPastOrder}
        customerName={customerName}
        customerEmail={customerEmail}
      />
      {Divider}
      <DesignInformation
        setIsUploadDesign={setIsUploadDesign}
        orderDesign={orderDesign}
        order={order}
      />
    </Box>
  );
}
