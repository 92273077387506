import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';

import { ArrowRightIcon, PencilIcon } from '@heroicons/react/24/outline';

import profilesAPI from '@/api/profiles';
import { ErrorAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import { CompanyInfoForm } from '@/components/EditProfilePage/CompanyInfoForm';
import { PersonalInfoForm } from '@/components/EditProfilePage/PersonalInfoForm';
import {
  ClearProfile,
  ContactExchangeForm,
  DeleteProfile,
} from '@/components/EditProfilePage/ProfileOperations';
import { ProfilePageAppearance } from '@/components/EditProfilePage/ProfilePageAppearance';
import { QuickLinksForm } from '@/components/EditProfilePage/QuickLinksForm';
import { SocialLinksForm } from '@/components/EditProfilePage/SocialLinksForm';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import { UnsavedChangesModal } from '@/components/UnsavedChangesPrompt';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';
import { WorkingProfileForm } from '@/pages/EditProfilePage';
import IProfile from '@/types/IProfile';

type ProfileForm = {
  profile: Partial<IProfile>;
};

export function EditProfileForm({
  profileGroupHash,
}: {
  profileGroupHash: string | null;
}) {
  const { userRole, orgID } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [, , profileID] = location.pathname.split('/');
  const {
    handleSubmit,
    formState: { isDirty, errors, isSubmitting, isSubmitSuccessful },
  } = useFormContext<WorkingProfileForm>();

  const { mutateAsync: saveProfile, isError: isProfileError } = useMutation({
    mutationFn: updateProfile,
  });

  // Confirms with the user if they want to navigate away if the form is
  // dirty. Does not intercept everything e.g. refreshes or closing the tab
  // but serves to reduce the possibility of a user accidentally losing their
  // progress.
  const blocker = useBlocker(isDirty && !isSubmitSuccessful);

  // Gating on !isSubmitSuccessful above makes the blocker stop blocking nav
  // after a successful submit. However, this can take a little time (there is a
  // render in between when isSubmitSuccessful is true but the blocker is still
  // ready to block), so we also have this useEffect that lets blocked requests
  // through if submit is succesful but the blocker hasn't gotten a chance to
  // stop blocking yet.
  useEffect(() => {
    if (blocker.state === 'blocked' && isSubmitSuccessful) {
      blocker.proceed();
    }
  }, [blocker, isSubmitSuccessful]);

  const isAdmin = userRole === 'org_admin';

  async function onSubmit(data: WorkingProfileForm) {
    const profileForm: ProfileForm = {
      profile: {
        first_name: data.first_name,
        middle_name: data.middle_name,
        last_name: data.last_name,
        city: data.city,
        state: data.state,
        country: data.country,
        post_code: data.post_code,
        company_name: data.company_name,
        company_website: data.company_website,
        company_phone_number: data.company_phone_number,
        email: data.email,
        mobile_number: data.mobile_number,
        extra_emails: data.extra_emails?.filter(email => !!email),
        extra_mobile_numbers: data.extra_mobile_numbers?.filter(
          phone => !!phone,
        ),
        extra_company_phone_numbers: data.extra_company_phone_numbers?.filter(
          phone => !!phone,
        ),
        photo: data.photo,
        profile_file_links: data.profile_file_links,
        profile_quick_links: data.profile_quick_links,
        profile_social_links: data.profile_social_links,
        note: data.note,
        job_title: data.job_title,
        contact_exchange_enabled: data.contact_exchange_enabled,
        pronouns: data.pronouns,
        street_address: data.street_address,
      },
    };

    await saveProfile(profileForm);

    navigate('/', {
      state: {
        success: MESSAGES.profile.edit.success,
        setupInfo: true,
      },
    });
  }

  async function updateProfile(data: ProfileForm) {
    if (orgID === undefined) {
      return;
    }

    await profilesAPI.updateProfile({
      orgID,
      profileID: parseInt(profileID),
      body: data,
    });
  }

  const hasFormErrors = Object.keys(errors).length > 0;

  return (
    <div className="pb-20">
      <div className="space-y-6 pb-6">
        <ProfileCustomisationWidget profileGroupHash={profileGroupHash} />
        {hasFormErrors && (
          <ErrorAlert message="Please review and correct the form to continue" />
        )}
        {isProfileError && <ErrorAlert message={MESSAGES.error.generic} />}
      </div>
      <PersonalInfoForm />
      <InfoPanelDivider />
      <SocialLinksForm />
      <InfoPanelDivider />
      <CompanyInfoForm />
      <InfoPanelDivider />
      <QuickLinksForm />
      <InfoPanelDivider />
      <ContactExchangeForm />
      <InfoPanelDivider />
      <ProfilePageAppearance />
      {isAdmin && (
        <>
          <InfoPanelDivider />
          <ClearProfile />
          <InfoPanelDivider />
          <DeleteProfile />
        </>
      )}
      <InfoPanelFooter>
        <div className="flex justify-end space-x-4">
          <Button
            buttonText="Discard changes"
            kind={BUTTON_KIND.WHITE}
            onClick={() => navigate('/')}
          />
          <Button
            buttonText="Save and close"
            onClick={handleSubmit(onSubmit)}
            loading={isSubmitting}
          />
        </div>
      </InfoPanelFooter>
      {blocker.state === 'blocked' && (
        <UnsavedChangesModal
          proceed={handleSubmit(onSubmit)}
          cancel={blocker.proceed}
          reset={blocker.reset}
          isLoading={isSubmitting}
        />
      )}
    </div>
  );
}

function ProfileCustomisationWidget({
  profileGroupHash,
}: {
  profileGroupHash: string | null;
}) {
  return (
    <div className="bg-brand-100 text-brand-900 flex flex-row p-3 rounded-md space-x-3">
      <div className="flex-shrink-0">
        <PencilIcon className="w-5 h-5 text-brand-500" />
      </div>
      <div className="flex flex-col space-y-2">
        <span className="text-brand-900 font-medium">
          Do you want to customise the profile appearance?
        </span>
        <span className="text-sm">
          Profile appearance, including background colours and company banner,
          is applied {profileGroupHash ? 'group' : 'organisation'}
          -wide. Customise {profileGroupHash ? 'this' : 'your'} profile design
          {profileGroupHash ? ' ' : ' in '}
          <span className="font-semibold">
            {profileGroupHash ? 'within the Group' : 'Settings'}.
          </span>
        </span>
        <a
          href={
            profileGroupHash
              ? `/edit-group/${profileGroupHash}`
              : '/templates/profile-design'
          }
          className="flex flex-row"
        >
          Design in {profileGroupHash ? 'Group' : 'Templates'}
          <ArrowRightIcon className="ml-1 w-5 h-5" />
        </a>
      </div>
    </div>
  );
}
