import React, { JSX } from 'react';

import { css, cx } from '@emotion/css';
import { Transition } from '@headlessui/react';
import {
  ArrowDownTrayIcon as DownloadIcon,
  ArrowTopRightOnSquareIcon as ExternalLinkIcon,
  GlobeAltIcon,
  MapPinIcon,
  PhoneIcon,
} from '@heroicons/react/20/solid';

import profilesAPI from '@/api/profiles';
import { ReactComponent as SocialFacebook } from '@/assets/svg/social/Facebook.svg';
import { ReactComponent as SocialGoogleMyBusiness } from '@/assets/svg/social/GoogleMyBusiness.svg';
import { ReactComponent as SocialInstagram } from '@/assets/svg/social/Instagram.svg';
import { ReactComponent as SocialLinkedIn } from '@/assets/svg/social/LinkedIn.svg';
import { ReactComponent as SocialPinterest } from '@/assets/svg/social/Pinterest.svg';
import { ReactComponent as SocialTiktok } from '@/assets/svg/social/TikTok.svg';
import { ReactComponent as SocialTwitter } from '@/assets/svg/social/Twitter.svg';
import { ReactComponent as SocialWechat } from '@/assets/svg/social/WeChat.svg';
import { ReactComponent as SocialWhatsapp } from '@/assets/svg/social/Whatsapp.svg';
import { ReactComponent as SocialYoutube } from '@/assets/svg/social/YouTube.svg';
import Button, {
  BUTTON_ICON_POSITION,
  BUTTON_KIND,
  BUTTON_SIZE,
} from '@/components/Button';
import MESSAGES from '@/constants/messages-en';
import { classNames, hexToRgba, stringHasContent } from '@/helpers/strings';
import useProgressiveImg from '@/hooks/useProgressiveImg';
import { IProfileLink, IProfileSocialLink } from '@/types/IProfile';

import placeholderImageGradient from './placeholder-gradient.svg';

interface IProfilePageDetails {
  addToContactsButton: () => JSX.Element;
  backgroundColor: string;
  cardholderEditingEnabled: boolean;
  companyAddress?: string | null;
  companyName?: string | null;
  companyPhoneNumber?: string | null;
  companyWebsite?: string | null;
  contactExchangeEnabled: boolean;
  email?: string | null;
  error?: boolean;
  jobTitle?: string | null;
  mobileNumber?: string | null;
  name: string;
  pronouns: string | null;
  note?: string | null;
  profileHashID: string;
  profileImageUrl?: string | null;
  quickLinks: IProfileLink[];
  textColor: string;
  socialLinks: IProfileSocialLink[];
  setIsShareDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IProfilePageContentBlock {
  title: string;
  children: React.ReactNode;
}

const ProfilePageContentBlock: React.FC<IProfilePageContentBlock> = ({
  children,
  title,
}) => {
  return (
    <div className="mt-8">
      <h2 className="text-lg font-semibold mb-3">{title}</h2>
      {children}
    </div>
  );
};

const ProfilePageDetails: React.FC<IProfilePageDetails> = props => {
  const {
    addToContactsButton,
    backgroundColor,
    cardholderEditingEnabled,
    companyAddress,
    companyName,
    companyPhoneNumber,
    companyWebsite,
    contactExchangeEnabled,
    email,
    error,
    jobTitle,
    mobileNumber,
    name,
    pronouns,
    note,
    profileHashID,
    profileImageUrl,
    quickLinks,
    textColor,
    socialLinks,
    setIsShareDetailsOpen,
  } = props;

  const BUTTON_SHARE_YOUR_DETAILS_LABEL =
    MESSAGES.profile.view.shareYourDetailsCtaLabel ||
    'MESSAGES.profile.view.shareYourDetailsCtaLabel';

  const styles = {
    profileImage: css`
      background: ${backgroundColor};
      border-color: ${backgroundColor};
    `,
    profileImageOverlay: css`
      background: ${hexToRgba(textColor, 0.1)};
      border-color: ${backgroundColor};
    `,
    textColor: css`
      color: ${textColor};
    `,
    textLink: css`
      &:hover {
        color: ${textColor};
        text-decoration: underline;
      }
    `,
    textColor80: css`
      color: ${hexToRgba(textColor, 0.8)};
    `,
    textColor50: css`
      color: ${hexToRgba(textColor, 0.5)};
    `,
    socialLink: css`
      background-color: ${hexToRgba(textColor, 0.05)};
      color: ${textColor};
      &:hover {
        background-color: ${hexToRgba(textColor, 0.25)};
        color: ${textColor};
      }
    `,
    contentButton: css`
      border-color: ${hexToRgba(textColor, 0.5)};
      color: ${textColor};
      &:hover {
        background: ${hexToRgba(textColor, 0.2)};
        color: ${textColor};
      }
    `,
    border: css`
      border-color: ${hexToRgba(textColor, 0.1)};
    `,
  };

  const companyDetails = [
    {
      content: companyAddress,
      hasContent: stringHasContent(companyAddress),
      icon: <MapPinIcon className="h-5 w-5" aria-hidden="true" />,
      name: 'Address',
    },
    {
      content: (
        <a
          className={cx(styles.textColor, styles.textLink, 'text-base')}
          href={`tel: ${companyPhoneNumber as string} `}
        >
          {companyPhoneNumber}
        </a>
      ),
      hasContent: stringHasContent(companyPhoneNumber),
      icon: <PhoneIcon className="h-5 w-5" aria-hidden="true" />,
      name: 'Phone',
    },
    {
      content: (
        <a
          className={cx(styles.textColor, styles.textLink, 'text-base')}
          href={
            companyWebsite && !companyWebsite.startsWith('http')
              ? `https://${companyWebsite}`
              : companyWebsite || ''
          }
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          {companyWebsite}
        </a>
      ),
      hasContent: stringHasContent(companyWebsite),
      icon: <GlobeAltIcon className="h-5 w-5" aria-hidden="true" />,
      name: 'Website',
    },
  ].filter(details => details.hasContent === true);

  const ProfilePicture = () => {
    const [src] = useProgressiveImg(
      placeholderImageGradient,
      profileImageUrl as string,
    );

    return (
      <img
        alt={`Profile of ${name} `}
        src={src}
        className={cx('w-full h-full')}
      />
    );
  };

  const errorMessage = error && (
    <div className="pt-24">
      Profile page can not be loaded right now. Please try again later.
    </div>
  );

  const content = (
    <>
      {(profileImageUrl || cardholderEditingEnabled) && (
        <div
          className={classNames(
            'h-28 w-full md:w-auto flex items-end md:justify-start space-x-4',
            profileImageUrl ? 'justify-between' : 'justify-end',
          )}
        >
          {profileImageUrl && (
            <div
              className={cx(
                styles.profileImage,
                'rounded-full overflow-hidden h-28 w-28 border-2',
              )}
            >
              <div className={cx(styles.profileImageOverlay, 'text-center')}>
                <Transition
                  enter="transition duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  show
                >
                  <ProfilePicture />
                </Transition>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="md:grid md:grid-cols-12 first:pt-20">
        <div className="md:col-span-8">
          <div className="pt-4">
            <div className="flex items-end">
              <h1 className="text-3xl leading-9 font-semibold">{name}</h1>
              <span className="ml-2 pb-0.5">{pronouns}</span>
            </div>
            {jobTitle && (
              <p
                className={cx(styles.textColor80, 'text-base text-opacity-80')}
              >
                {jobTitle}
                {companyName && ` at ${companyName}`}
              </p>
            )}
          </div>
          {(mobileNumber || email || socialLinks) && (
            <div className="pt-2">
              {mobileNumber && (
                <p>
                  <a
                    className={cx(
                      styles.textColor,
                      styles.textLink,
                      'text-base font-medium',
                    )}
                    href={`tel:${mobileNumber} `}
                    title={`Call ${mobileNumber} `}
                  >
                    {mobileNumber}
                  </a>
                </p>
              )}
              {email && (
                <p>
                  <a
                    className={cx(
                      styles.textColor,
                      styles.textLink,
                      'text-base font-medium',
                    )}
                    href={`mailto:${email} `}
                    title={`Send email to ${email} `}
                  >
                    {email}
                  </a>
                </p>
              )}
              {socialLinks && (
                <ul className="flex gap-3 flex-wrap mt-4">
                  {socialLinks
                    .sort((a: IProfileSocialLink, b: IProfileSocialLink) =>
                      a.order > b.order ? 1 : -1,
                    )
                    .map((socialLink: IProfileSocialLink) => {
                      const {
                        order,
                        type: socialType,
                        value: socialLinkHref,
                      } = socialLink;
                      const iconClass = 'w-5 h-auto';
                      const socialIcons: Record<string, JSX.Element> = {
                        facebook: <SocialFacebook className={iconClass} />,
                        instagram: <SocialInstagram className={iconClass} />,
                        linkedin: <SocialLinkedIn className={iconClass} />,
                        pinterest: <SocialPinterest className={iconClass} />,
                        tiktok: <SocialTiktok className={iconClass} />,
                        twitter: <SocialTwitter className={iconClass} />,
                        youtube: <SocialYoutube className={iconClass} />,
                        whatsapp: <SocialWhatsapp className={iconClass} />,
                        wechat: <SocialWechat className={iconClass} />,
                        googlemybusiness: (
                          <SocialGoogleMyBusiness className={iconClass} />
                        ),
                      };
                      const key = `${socialType} -${order} `;
                      return (
                        <li key={key}>
                          <a
                            className={cx(
                              styles.socialLink,
                              'rounded-full w-12 h-12 inline-flex justify-center items-center transition-all duration-300',
                            )}
                            href={
                              socialLinkHref &&
                              !socialLinkHref.startsWith('http')
                                ? `https://${socialLinkHref}`
                                : socialLinkHref || ''
                            }
                            rel="noreferrer noopener nofollow"
                            target="_blank"
                            onClick={() => {
                              profilesAPI.viewSocialLink(
                                profileHashID,
                                socialLink.type,
                              );
                            }}
                          >
                            {socialIcons[socialType]}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          )}
          {contactExchangeEnabled && (
            <>
              <div className="md:hidden mt-6">
                <Button
                  buttonText={BUTTON_SHARE_YOUR_DETAILS_LABEL}
                  fullWidth
                  kind={BUTTON_KIND.COLOR_OMITTED}
                  onClick={() => setIsShareDetailsOpen(true)}
                  size={BUTTON_SIZE.XLARGE}
                  className={styles.contentButton}
                />
              </div>
              <div className="hidden md:block mt-6">
                <Button
                  buttonText={BUTTON_SHARE_YOUR_DETAILS_LABEL}
                  kind={BUTTON_KIND.COLOR_OMITTED}
                  onClick={() => setIsShareDetailsOpen(true)}
                  size={BUTTON_SIZE.XLARGE}
                  className={styles.contentButton}
                />
              </div>
            </>
          )}
        </div>
        <div className="col-span-4 pt-4 flex-col items-end hidden md:flex">
          {addToContactsButton()}
        </div>
      </div>
      {quickLinks && quickLinks.length > 0 && (
        <ProfilePageContentBlock title="Links">
          <div className="grid grid-cols-12 md:gap-x-4 gap-y-3">
            {quickLinks
              .sort((a: IProfileLink, b: IProfileLink) =>
                a.order > b.order ? 1 : -1,
              )
              .map((link: IProfileLink) => {
                const { href, label, linkType, order } = link;
                const key = order;
                const iconExternalLink = (
                  <ExternalLinkIcon className="h-5 w-5" aria-hidden="true" />
                );
                const iconDownload = (
                  <DownloadIcon className="h-5 w-5" aria-hidden="true" />
                );
                const quickLinkIcon =
                  linkType === 'file' ? iconDownload : iconExternalLink;
                return (
                  <div className="col-span-12 md:col-span-6" key={key}>
                    <Button
                      buttonText={label}
                      external
                      fullWidth
                      href={href}
                      icon={quickLinkIcon}
                      iconPos={BUTTON_ICON_POSITION.RIGHT}
                      kind={BUTTON_KIND.COLOR_OMITTED}
                      size={BUTTON_SIZE.XLARGE}
                      className={styles.contentButton}
                    />
                  </div>
                );
              })}
          </div>
        </ProfilePageContentBlock>
      )}
      {companyName && companyDetails.length > 0 && (
        <ProfilePageContentBlock title={companyName}>
          <dl>
            {companyDetails.map(detail => (
              <div
                className={cx(
                  styles.border,
                  'flex py-3 first:border-t border-b',
                )}
                key={detail.name}
              >
                <dt>
                  <span className={cx(styles.textColor50, 'block mr-3 pt-0.5')}>
                    {detail.icon}
                  </span>
                  <span className="sr-only">{detail.name}</span>
                </dt>
                <dd className="whitespace-pre-wrap">{detail.content}</dd>
              </div>
            ))}
          </dl>
        </ProfilePageContentBlock>
      )}
      {note && (
        <ProfilePageContentBlock title="Note">
          <p className={cx(styles.textColor80, 'whitespace-pre-wrap')}>
            {note}
          </p>
        </ProfilePageContentBlock>
      )}
    </>
  );

  return <div>{errorMessage || content}</div>;
};

export { ProfilePageDetails as default };
