import { IIcon } from '@/types/IIcon';

const Card = ({ className }: IIcon) => {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 5C22 4.44772 21.5523 4 21 4H6.00094C6.00091 4 6.00098 4 6.00094 4C3.7918 4 2 5.79086 2 8V19C2 19.5523 2.44772 20 3 20H17.9995C17.9996 20 17.9995 20 17.9995 20C20.2087 20 22 18.2091 22 16V5ZM17.9995 18H4V8C4 6.89543 4.89543 6 6 6C6.00004 6 5.99997 6 6 6H20V16C20 17.1046 19.1041 18 17.9995 18C17.9996 18 17.9995 18 17.9995 18ZM11.5 15C11.5 14.4477 11.0523 14 10.5 14H7C6.44771 14 6 14.4477 6 15C6 15.5523 6.44771 16 7 16H10.5C11.0523 16 11.5 15.5523 11.5 15Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Card;
