import { useState } from 'react';

import SimpleAnalytics from '@/components/Analytics/SimpleAnalytics';
import Layout from '@/components/Layout';

export default function OldAnalyticsPage() {
  const [profileIds, setProfileIds] = useState<number[]>([]);

  return (
    <Layout pageName="Analytics">
      <SimpleAnalytics setProfileIds={ids => setProfileIds(ids)} />
    </Layout>
  );
}
