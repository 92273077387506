import { useFormContext } from 'react-hook-form';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';

import Button, { BUTTON_ICON_POSITION, BUTTON_KIND } from '@/components/Button';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import Input from '@/components/Input';
import ToggleField from '@/components/ToggleField';
import MESSAGES from '@/constants/messages-en';
import { WorkingProfileForm } from '@/pages/EditProfilePage';
import { IProfileSocialLink } from '@/types/IProfile';
import {
  ProperSocialsText,
  SocialsText,
  TaptSocialsSchema,
} from '@/types/TaptSocials';

export function SocialLinksForm() {
  const { watch, setValue } = useFormContext<WorkingProfileForm>();

  const profileSocialLinks = watch('profile_social_links');

  function addLink() {
    const profileLinks = [...profileSocialLinks];
    profileLinks.push({
      order: profileLinks.length,
      type: 'facebook',
      value: '',
      personal: false,
    });
    setValue('profile_social_links', profileLinks);
  }

  function removeLink(index: number) {
    const profileLinks = [...profileSocialLinks];
    profileLinks.splice(index, 1);
    const orderedLinks = profileLinks.map((link, index) => ({
      ...link,
      order: index,
    }));
    setValue('profile_social_links', orderedLinks);
  }

  return (
    <InfoPanelContainer
      title="Social Media Links"
      information={MESSAGES.profile.edit.social.heading}
      hint={MESSAGES.profile.edit.social.description}
    >
      <div className="space-y-3">
        {profileSocialLinks &&
          profileSocialLinks.map((link, index) => (
            <EditSocialLink
              key={index}
              link={link}
              removeLink={() => removeLink(index)}
              isLast={profileSocialLinks.length - 1 === index}
            />
          ))}
        <Button
          buttonText="Add new social link"
          icon={<PlusIcon />}
          iconPos={BUTTON_ICON_POSITION.LEFT}
          kind={BUTTON_KIND.WHITE}
          onClick={addLink}
        />
      </div>
    </InfoPanelContainer>
  );
}

type EditSocialLinkProps = {
  link: IProfileSocialLink;
  removeLink: () => void;
  isLast: boolean;
};

function EditSocialLink({ link, removeLink, isLast }: EditSocialLinkProps) {
  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<WorkingProfileForm>();

  function onTypeSelect(type: string) {
    const profileSocialLinks = getValues('profile_social_links');
    const index = profileSocialLinks.findIndex(
      item => item.order === link.order,
    );

    const validType = TaptSocialsSchema.safeParse(type);
    if (validType.success) {
      setValue(`profile_social_links.${index}.type`, validType.data);
    }
  }

  function onValueChange(value: string) {
    const profileSocialLinks = getValues('profile_social_links');
    const index = profileSocialLinks.findIndex(
      item => item.order === link.order,
    );

    setValue(`profile_social_links.${index}.value`, value);
  }

  function moveLink(direction: 'up' | 'down') {
    const profileSocialLinks = getValues('profile_social_links');
    const newOrder = direction === 'up' ? link.order - 1 : link.order + 1;

    if (newOrder < 0 || newOrder >= profileSocialLinks.length) {
      return;
    }

    const targetLinkIndex = profileSocialLinks.findIndex(
      item => item.order === newOrder,
    );
    profileSocialLinks[targetLinkIndex].order = link.order;
    link.order = newOrder;

    const updatedLinks = profileSocialLinks.sort((a, b) => a.order - b.order);

    setValue('profile_social_links', updatedLinks);
  }

  function onPersonalToggle(enabled: boolean) {
    const profileSocialLinks = getValues('profile_social_links');
    const index = profileSocialLinks.findIndex(
      item => item.order === link.order,
    );

    setValue(`profile_social_links.${index}.personal`, enabled);
  }

  function getLinkIndex() {
    const profileSocialLinks = getValues('profile_social_links');
    return profileSocialLinks.findIndex(
      item => item.value === link.value && item.order === link.order,
    );
  }

  const error = errors.profile_social_links?.[getLinkIndex()];

  return (
    <div className="flex flex-col-reverse xl:flex-row border border-gray-300 rounded-md overflow-hidden">
      <div className="flex flex-row xl:flex-col justify-end xl:justify-between space-x-2 xl:space-x-0 bg-gray-50 p-3">
        <button
          className="border border-gray-300 bg-white rounded-md p-2 disabled:opacity-40 hover:bg-gray-50"
          onClick={() => moveLink('up')}
          disabled={link.order === 0}
        >
          <ArrowUpIcon
            className="w-5 h-5 text-gray-500"
            strokeWidth={2}
            aria-hidden="true"
          />
        </button>
        <button
          className="border border-gray-300 bg-white rounded-md p-2 disabled:opacity-40 hover:bg-gray-50"
          onClick={() => moveLink('down')}
          disabled={isLast}
        >
          <ArrowDownIcon
            className="w-5 h-5 text-gray-500"
            strokeWidth={2}
            aria-hidden="true"
          />
        </button>
      </div>
      <div className="flex flex-col w-full py-4 px-6 space-y-3">
        <div className="flex flex-col xl:flex-row justify-between gap-4">
          <div className="flex flex-col space-y-1 flex-1">
            <label
              htmlFor={`profile-social-link-${link.type}-${link.value}`}
              className="text-sm font-medium text-gray-700"
            >
              Social channel
            </label>
            <select
              name={`profile-social-link-${link.type}-${link.value}`}
              id={`profile-social-link-${link.type}-${link.value}`}
              value={link.type}
              onChange={e => onTypeSelect(e.target.value)}
              className="px-3 py-2 border border-gray-300 rounded-md focus:ring-brand-500 focus:border-brand-500 shadow-sm"
            >
              {SocialsText.map((social, index) => (
                <option key={index} value={social}>
                  {ProperSocialsText[index]}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col flex-1">
            <Input
              label="Social URL"
              type="text"
              value={link.value}
              onChange={onValueChange}
              message="Clicking the icon will go here"
              error={!!error?.value}
            />
            {error && error.value && (
              <span className="text-sm text-red-400 font-medium">
                {error.value.message}
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-between">
          <button
            className="text-brand-500 font-medium text-sm"
            onClick={removeLink}
          >
            Remove link
          </button>
          <ToggleField
            label="Personal link"
            lableColor="gray-400"
            enabled={link.personal}
            setter={onPersonalToggle}
          />
        </div>
      </div>
    </div>
  );
}
