import { InputHTMLAttributes, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/20/solid';

import InfoPanelContainer from '@/components/InfoPanelContainer';
import { FormInput } from '@/components/Input';
import UploadPhoto from '@/components/UploadPhoto';
import { PROFILE_IMAGE } from '@/constants/files';
import MESSAGES from '@/constants/messages-en';
import { WorkingProfileForm } from '@/pages/EditProfilePage';
import IFile from '@/types/IFile';

const personalInfoFieldNames = [
  'first_name',
  'last_name',
  'middle_name',
  'job_title',
  'email',
  'pronouns',
  'mobile_number',
  'note',
] as const;

type PersonalInfoField = {
  name: (typeof personalInfoFieldNames)[number];
  label: string;
} & InputHTMLAttributes<HTMLInputElement>;

export function PersonalInfoForm() {
  const {
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<WorkingProfileForm>();

  const [photo, setPhoto] = useState<IFile | undefined>(undefined);

  const extraEmails = watch('extra_emails');
  const extraMobileNumbers = watch('extra_mobile_numbers');

  useEffect(() => {
    if (photo === undefined) {
      setValue('photo', null);
    } else {
      setValue('photo', photo);
    }
  }, [photo]);

  function addExtraField(type: 'email' | 'phone') {
    if (type === 'email') {
      const emails = getValues('extra_emails');
      setValue('extra_emails', emails === null ? [''] : [...emails, '']);
    } else if (type === 'phone') {
      const mobileNumbers = getValues('extra_mobile_numbers');
      setValue(
        'extra_mobile_numbers',
        mobileNumbers === null ? [''] : [...mobileNumbers, ''],
      );
    }
  }

  function removeExtraField(type: 'email' | 'phone', index: number) {
    if (type === 'email') {
      const emails = getValues('extra_emails');
      const filtered = emails?.filter((_, i) => i !== index) ?? null;
      setValue('extra_emails', filtered);
    } else if (type === 'phone') {
      const mobileNumbers = getValues('extra_mobile_numbers');
      const filtered = mobileNumbers?.filter((_, i) => i !== index) ?? null;
      setValue('extra_mobile_numbers', filtered);
    }
  }

  const nameFields: PersonalInfoField[] = [
    {
      name: 'first_name',
      label: 'First name',
      autoComplete: 'given-name',
    },
    {
      name: 'middle_name',
      label: 'Middle name',
      autoComplete: 'additional-name',
    },
    {
      name: 'last_name',
      label: 'Last name',
      autoComplete: 'family-name',
    },
  ];
  const variableFields: PersonalInfoField[] = [
    {
      name: 'email',
      label:
        extraEmails && extraEmails.length > 0
          ? 'Primary email address'
          : 'Email address',
      placeholder: 'Enter work email address',
      autoComplete: 'work email',
    },
    {
      name: 'mobile_number',
      label:
        extraMobileNumbers && extraMobileNumbers.length > 0
          ? 'Primary mobile number'
          : 'Mobile number',
      placeholder: 'Enter work mobile number',
      autoComplete: 'mobile tel',
    },
  ];

  return (
    <InfoPanelContainer
      title="Personal Information"
      information={MESSAGES.profile.edit.personalInfo}
    >
      <div className="space-y-4">
        <UploadPhoto
          photo={photo}
          setPhoto={setPhoto}
          maxWidth={PROFILE_IMAGE.MAX_WIDTH}
          maxHeight={PROFILE_IMAGE.MAX_HEIGHT}
        />

        {/* name section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {nameFields.map(field => (
            <div key={field.name}>
              <FormInput
                error={!!errors[field.name]}
                {...field}
                {...register(field.name)}
              />
              {errors[field.name] && (
                <span className="text-sm text-red-400">
                  {errors[field.name]?.message}
                </span>
              )}
            </div>
          ))}
        </div>

        {/* Job title and pronouns section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="col-span-1 md:col-span-2">
            <FormInput
              label="Job title/Position"
              autoComplete="organization-title"
              error={!!errors.job_title}
              {...register('job_title')}
            />
          </div>

          <FormInput
            label="Pronouns"
            error={!!errors.pronouns}
            {...register('pronouns')}
          />
        </div>

        {/* extra email and phone numbers */}
        <div className="grid grid-cols-2 gap-4">
          {variableFields.map((field, index) => (
            <div key={index} className="space-y-4">
              <div className="flex items-center space-x-2">
                <FormInput
                  error={!!errors[field.name]}
                  {...field}
                  {...register(field.name)}
                />
                <button
                  className="pt-6"
                  onClick={() =>
                    addExtraField(field.name === 'email' ? 'email' : 'phone')
                  }
                >
                  <PlusCircleIcon className="w-5 h-5 text-brand-500" />
                </button>
              </div>
              {errors[field.name] && (
                <span className="text-sm text-red-400 self-start">
                  {errors[field.name]?.message}
                </span>
              )}
              {field.name === 'email' &&
                extraEmails?.map((email, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <FormInput
                      label="Email address"
                      value={email}
                      onChange={e => {
                        const emails = extraEmails;
                        emails[index] = e.target.value;
                        setValue('extra_emails', emails);
                      }}
                      error={!!errors.extra_emails?.[index]}
                    />
                    <button
                      className="pt-6"
                      onClick={() => removeExtraField('email', index)}
                    >
                      <MinusCircleIcon className="w-5 h-5 text-red-400" />
                    </button>
                  </div>
                ))}

              {field.name === 'mobile_number' &&
                extraMobileNumbers?.map((phone, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <FormInput
                      label="Mobile number"
                      value={phone}
                      onChange={e => {
                        const mobileNumbers = extraMobileNumbers;
                        mobileNumbers[index] = e.target.value;
                        setValue('extra_mobile_numbers', mobileNumbers);
                      }}
                      error={!!errors.extra_mobile_numbers?.[index]}
                    />
                    <button
                      className="pt-6"
                      onClick={() => removeExtraField('phone', index)}
                    >
                      <MinusCircleIcon className="w-5 h-5 text-red-400" />
                    </button>
                  </div>
                ))}
            </div>
          ))}
        </div>

        {/* profile note */}
        <div className="flex flex-col space-y-2">
          <label
            htmlFor="note"
            className="text-sm font-medium text-gray-900 leading-5"
          >
            Profile note
          </label>
          <textarea
            id="note"
            className="shadow-sm border border-gray-300 rounded-md text-base leading-6"
            rows={3}
            {...register('note')}
          />
          <span className="text-gray-500 text-sm">
            Write a few sentences about yourself.
          </span>
        </div>
      </div>
    </InfoPanelContainer>
  );
}
