import React, { useEffect, useRef } from 'react';

import { CustomScroll } from './CustomScroll';

interface ICustomInfiniteScrollProps {
  fetchMore: () => void;
  className: string;
  children: React.ReactNode;
  customRerenderArray?: any[];
}

const CustomInfiniteScroll = (props: ICustomInfiniteScrollProps) => {
  const { fetchMore, className, children, customRerenderArray } = props;

  const listInnerRef = useRef<HTMLInputElement>(null);

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollHeight - scrollTop - clientHeight < 10) {
        fetchMore();
      }
    }
  };

  useEffect(() => {
    if (listInnerRef.current) {
      const { scrollHeight, clientHeight } = listInnerRef.current;
      listInnerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  }, customRerenderArray || []);

  return (
    <>
      <CustomScroll
        onScroll={handleScroll}
        ref={listInnerRef}
        className={className}
      >
        {children}
      </CustomScroll>
    </>
  );
};

export default CustomInfiniteScroll;
