import { IIcon } from '@/types/IIcon';

const TaptUnion = (props: IIcon) => {
  return (
    <svg
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.6064 4.63823C22.6064 6.64757 20.9683 8.27646 18.9476 8.27646C16.927 8.27646 15.2889 6.64757 15.2889 4.63823C15.2889 2.62889 16.927 1 18.9476 1C20.9683 1 22.6064 2.62889 22.6064 4.63823Z"
        fill="currentColor"
      />
      <path
        d="M12.5026 8.79996C12.5026 10.8093 10.8646 12.4382 8.84391 12.4382C6.82325 12.4382 5.18518 10.8093 5.18518 8.79996C5.18518 6.79062 6.82325 5.16173 8.84391 5.16173C10.8646 5.16173 12.5026 6.79062 12.5026 8.79996Z"
        fill="currentColor"
      />
      <path
        d="M4.65873 22.4853C6.6794 22.4853 8.31746 20.8564 8.31746 18.8471C8.31746 16.8377 6.6794 15.2088 4.65873 15.2088C2.63807 15.2088 1 16.8377 1 18.8471C1 20.8564 2.63807 22.4853 4.65873 22.4853Z"
        fill="currentColor"
      />
      <path
        d="M12.5026 28.897C12.5026 30.9064 10.8646 32.5353 8.84391 32.5353C6.82325 32.5353 5.18518 30.9064 5.18518 28.897C5.18518 26.8877 6.82325 25.2588 8.84391 25.2588C10.8646 25.2588 12.5026 26.8877 12.5026 28.897Z"
        fill="currentColor"
      />
      <path
        d="M18.9476 36.697C20.9683 36.697 22.6064 35.0681 22.6064 33.0588C22.6064 31.0494 20.9683 29.4205 18.9476 29.4205C16.927 29.4205 15.2889 31.0494 15.2889 33.0588C15.2889 35.0681 16.927 36.697 18.9476 36.697Z"
        fill="currentColor"
      />
      <path
        d="M32.7129 28.897C32.7129 30.9064 31.0749 32.5353 29.0542 32.5353C27.0336 32.5353 25.3955 30.9064 25.3955 28.897C25.3955 26.8877 27.0336 25.2588 29.0542 25.2588C31.0749 25.2588 32.7129 26.8877 32.7129 28.897Z"
        fill="currentColor"
      />
      <path
        d="M33.2394 22.4853C35.2601 22.4853 36.8981 20.8564 36.8981 18.8471C36.8981 16.8377 35.2601 15.2088 33.2394 15.2088C31.2187 15.2088 29.5807 16.8377 29.5807 18.8471C29.5807 20.8564 31.2187 22.4853 33.2394 22.4853Z"
        fill="currentColor"
      />
      <path
        d="M32.7129 8.79996C32.7129 10.8093 31.0749 12.4382 29.0542 12.4382C27.0336 12.4382 25.3955 10.8093 25.3955 8.79996C25.3955 6.79062 27.0336 5.16173 29.0542 5.16173C31.0749 5.16173 32.7129 6.79062 32.7129 8.79996Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TaptUnion;
