import { useState } from 'react';

import adminAPI from '@/api/admin';
import {
  IPlatformSubscription,
  SubscriptionTypes,
} from '@/types/IOrganisation';
import SubscriptionStatus from '@/types/SubscriptionStatus';

import Button, { BUTTON_KIND } from './Button';
import Modal from './Modals/Modal';

interface IPilotsListItem {
  id: number;
  name?: string;
  insertedAt: Date;
  saasEnabled: boolean;
  subscription: IPlatformSubscription | null;
  totalProfiles: number;
  platformSubscriptionStatus: string;
}

export default function PilotsListItem({
  id,
  name,
  insertedAt,
  subscription,
  totalProfiles,
  platformSubscriptionStatus,
}: IPilotsListItem) {
  const [pilotSubscription, setPilotSubscription] =
    useState<IPlatformSubscription | null>(subscription);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>(
    platformSubscriptionStatus,
  );
  const [
    isCancellationConfirmationModalOpen,
    setIsCancellationConfirmationModalOpen,
  ] = useState(false);
  const [isCancellationConfirmed, setIsCancellationConfirmed] = useState(false);

  const hasPrepaidPlan = pilotSubscription?.type === SubscriptionTypes.PREPAID;
  const canCreatePrepaidPlan = !pilotSubscription && !hasPrepaidPlan;
  const hasSealPlan = pilotSubscription?.type === SubscriptionTypes.SEAL;

  async function createPrepaidSubscription(organisation_id: number) {
    const res = await adminAPI.createPilot({
      organisation_id,
      plan_id: SubscriptionTypes.PREPAID as string,
    });

    return res.data.data;
  }

  async function deletePilot(subscriptionId: number) {
    return await adminAPI.deletePilot(subscriptionId);
  }

  async function cancelSubscription(subscriptionId: number) {
    const res = await adminAPI.cancelSubscription(subscriptionId);

    return res.data.data;
  }

  const updateSubscriptionStatus = (
    subscription: IPlatformSubscription | null,
  ) => {
    if (subscription) {
      setSubscriptionStatus(SubscriptionStatus.ACTIVE);
      return;
    }

    if (totalProfiles > 5) {
      setSubscriptionStatus(SubscriptionStatus.INACTIVE);
      return;
    }

    setSubscriptionStatus(SubscriptionStatus.FREE);
  };

  return (
    <li key={id}>
      <div className="block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div className="min-w-0 flex-1 md:grid md:grid-cols-8 md:gap-4 xl:grid-cols-9">
              <div className="col-span-4 md:col-span-1 flex items-center space-x-4 w-full">
                <p className="text-sm font-medium text-gray-900 truncate">
                  #{id}
                </p>
              </div>
              <div className="flex items-center">
                <div className="min-w-0">
                  <p className="text-sm text-gray-900 truncate">{name}</p>
                </div>
              </div>
              <div className="flex md:hidden xl:flex items-center">
                {insertedAt && (
                  <p className="text-sm text-gray-900">
                    {insertedAt.toLocaleString()}
                  </p>
                )}
              </div>
              <div>
                <Button
                  kind={BUTTON_KIND.WHITE}
                  buttonText="Log in"
                  href={`/?scope=user&uOrgID=${id}&uPlatformSubscriptionStatus=${subscriptionStatus}`}
                  external
                />
              </div>
              <div className="flex items-center">
                <div className="min-w-0">
                  <p className="text-sm text-gray-900 truncate">
                    {subscriptionStatus}
                  </p>
                </div>
              </div>
              <div>
                {hasPrepaidPlan && (
                  <Button
                    kind={BUTTON_KIND.RED}
                    buttonText="Delete prepaid plan"
                    onClick={() => {
                      deletePilot(pilotSubscription.id).then(() => {
                        setPilotSubscription(null);
                        updateSubscriptionStatus(null);
                      });
                    }}
                  />
                )}

                {canCreatePrepaidPlan && (
                  <Button
                    kind={BUTTON_KIND.PRIMARY}
                    buttonText="Enable prepaid plan"
                    onClick={() => {
                      createPrepaidSubscription(id).then(data => {
                        setPilotSubscription(data);
                        updateSubscriptionStatus(data);
                      });
                    }}
                  />
                )}

                {hasSealPlan && pilotSubscription.cancelled_at && (
                  <Button buttonText="Cancellation pending" disabled />
                )}

                {hasSealPlan && pilotSubscription.cancelled_at === null && (
                  <>
                    <Button
                      kind={BUTTON_KIND.RED}
                      buttonText="Schedule cancellation"
                      onClick={() => {
                        setIsCancellationConfirmationModalOpen(true);
                      }}
                    />

                    <Modal
                      isOpen={isCancellationConfirmationModalOpen}
                      setIsOpen={setIsCancellationConfirmationModalOpen}
                      dialogTitle="Are you sure you want to cancel this platform fee?"
                      dialogDescription="An email will be sent to the client notifying them of their platform fee cancellation."
                      onSuccess={() => {
                        setIsCancellationConfirmationModalOpen(false);
                        setIsCancellationConfirmed(false);
                        cancelSubscription(pilotSubscription.id).then(data => {
                          setPilotSubscription(data);
                        });
                      }}
                      onCancel={() => {
                        setIsCancellationConfirmationModalOpen(false);
                        setIsCancellationConfirmed(false);
                      }}
                      checkbox={isCancellationConfirmed}
                      setCheckbox={setIsCancellationConfirmed}
                      isDisabled={!isCancellationConfirmed}
                      checkboxDescription="I am sure I want to cancel this platform fee."
                      successButtonText="Procceed"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
