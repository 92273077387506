import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/20/solid';

import InfoPanelContainer from '@/components/InfoPanelContainer';
import { FormInput } from '@/components/Input';
import MESSAGES from '@/constants/messages-en';
import { WorkingProfileForm } from '@/pages/EditProfilePage';

const companyInfoFieldNames = [
  'company_name',
  'company_website',
  'city',
  'country',
  'state',
  'post_code',
  'street_address',
  'company_phone_number',
] as const;

type CompanyInfoField = {
  name: (typeof companyInfoFieldNames)[number];
  label: string;
} & InputHTMLAttributes<HTMLInputElement>;

export function CompanyInfoForm() {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<WorkingProfileForm>();

  function addExtraPhoneNumber() {
    const phoneNumbers = extraCompanyPhoneNumbers;
    const updated = phoneNumbers === null ? [''] : [...phoneNumbers, ''];
    setValue('extra_company_phone_numbers', updated);
  }

  function removeExtraPhoneNumber(index: number) {
    const phoneNumbers = extraCompanyPhoneNumbers;
    const updated = phoneNumbers?.filter((_, i) => i !== index) ?? null;
    setValue('extra_company_phone_numbers', updated);
  }

  const address: CompanyInfoField[] = [
    {
      name: 'city',
      label: 'City',
    },
    {
      name: 'state',
      label: 'State / province',
    },
    {
      name: 'post_code',
      label: 'Postcode',
      autoComplete: 'postal-code',
    },
  ];

  const companyInfoFields: Record<string, CompanyInfoField[]> = {
    first: [
      {
        name: 'company_name',
        label: 'Company name',
        autoComplete: 'organization',
      },
      {
        name: 'company_website',
        label: 'Company website',
        autoComplete: 'url',
      },
    ],
  };

  const extraCompanyPhoneNumbers = watch('extra_company_phone_numbers');

  return (
    <InfoPanelContainer
      title="Company Information"
      hint={MESSAGES.profile.edit.company}
    >
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        {/* Company name and website */}
        <div className="grid grid-cols-1 xl:grid-cols-2 xl:col-span-2 gap-4">
          {companyInfoFields.first.map(field => (
            <div key={field.name} className="flex flex-col space-y-2">
              <FormInput
                error={!!errors[field.name]}
                {...field}
                {...register(field.name)}
              />
              {errors[field.name] && (
                <span className="text-sm text-red-400 font-medium">
                  {errors[field.name]?.message}
                </span>
              )}
            </div>
          ))}
        </div>

        {/* Company phone number */}
        <div className="space-y-4">
          <div className="col-span-1 flex space-x-2">
            <FormInput
              label="Company phone number"
              placeholder="Enter company number"
              error={!!errors.company_phone_number}
              {...register('company_phone_number')}
            />
            <button className="pt-6" onClick={addExtraPhoneNumber}>
              <PlusCircleIcon className="w-5 h-5 text-brand-500" />
            </button>
          </div>

          {/* Extra phone numbers */}
          {extraCompanyPhoneNumbers?.map((phone, index) => (
            <div key={index} className="flex col-span-1 space-x-2">
              <FormInput
                label="Direct phone number"
                value={phone}
                onChange={e => {
                  const phoneNumbers = extraCompanyPhoneNumbers;
                  phoneNumbers[index] = e.target.value;
                  setValue('extra_company_phone_numbers', phoneNumbers);
                }}
                placeholder="Enter direct phone number"
              />
              <button
                className="pt-6"
                onClick={() => removeExtraPhoneNumber(index)}
              >
                <MinusCircleIcon className="w-5 h-5 text-red-400" />
              </button>
            </div>
          ))}
        </div>

        {/* Street address */}
        <div className="xl:col-span-2">
          <FormInput
            error={!!errors.street_address}
            label="Street address"
            autoComplete="street-address"
            {...register('street_address')}
          />
        </div>

        {/* City, state, and post code */}
        <div className="grid grid-cols-1 xl:grid-cols-3 xl:col-span-2 gap-4">
          {address.map((item, index) => (
            <FormInput
              key={index}
              error={!!errors[item.name]}
              {...item}
              {...register(item.name)}
            />
          ))}
        </div>

        {/* Country */}
        <div className="col-span-1">
          <FormInput
            label="Country"
            autoComplete="country-name"
            error={!!errors.country}
            {...register('country')}
          />
        </div>
      </div>
    </InfoPanelContainer>
  );
}
