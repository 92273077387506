import { z } from 'zod';

import { FileSchema } from './IFile';

export const ProperSocialsText = [
  'Linkedin',
  'Facebook',
  'Twitter',
  'Instagram',
  'YouTube',
  'TikTok',
  'Whatsapp',
  'Pinterest',
  'Google My Business',
  'Threads',
  'Discord',
  'Twitch',
  'PayPal',
  'WeChat',
  'Behance',
] as const;

export type ProperTaptSocials = (typeof ProperSocialsText)[number];

export const SocialsText = [
  'linkedin',
  'facebook',
  'twitter',
  'instagram',
  'youtube',
  'tiktok',
  'whatsapp',
  'pinterest',
  'googlemybusiness',
  'threads',
  'discord',
  'twitch',
  'paypal',
  'wechat',
  'behance',
] as const;

export type TaptSocials = (typeof SocialsText)[number];

export const TaptSocialsSchema = z.enum(SocialsText);

export const ProfileQuickLinkSchema = z.object({
  order: z.number(),
  title: z.string(),
  value: z.string(),
});

export const ProfileFileLinkSchema = z.object({
  file_id: z.number(),
  file: FileSchema,
  order: z.number(),
  title: z.string().min(1, { message: 'Required' }),
});

export const IncompleteProfileLinkSchema = z.object({
  file: z.undefined(),
  file_id: z.undefined(),
  order: z.number(),
  title: z.string(),
});

export const ProfileFileLinkUnionSchema = z.union([
  ProfileFileLinkSchema.extend({ status: z.literal('complete') }),
  IncompleteProfileLinkSchema.extend({ status: z.literal('incomplete') }),
]);

export type WorkingProfileFileLink = z.infer<typeof ProfileFileLinkUnionSchema>;

export const ProfileSocialLinkSchema = z.object({
  order: z.number(),
  value: z.string().min(1, { message: 'Required' }),
  type: z.union([
    z.literal('linkedin'),
    z.literal('facebook'),
    z.literal('twitter'),
    z.literal('instagram'),
    z.literal('youtube'),
    z.literal('tiktok'),
    z.literal('whatsapp'),
    z.literal('pinterest'),
    z.literal('googlemybusiness'),
    z.literal('threads'),
    z.literal('discord'),
    z.literal('twitch'),
    z.literal('paypal'),
    z.literal('wechat'),
    z.literal('behance'),
  ]),
  personal: z.boolean(),
});
